// .dark {
//     .form-select {
//         background: transparent;
//         color: #fff;
//         border-color: #fff;
//         // appearance: none
//         background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
//         background-repeat: no-repeat;
//         background-position: right 0.75rem center;
//         background-size: 16px 12px;
//     }
//     .form-group {
//         .input {
//             color: #fff;
//         }
//     }
// }
.dark {
  .page {
    .react-datepicker__input-container input {
      background: transparent;
    }
    .form-select {
      color: #fff;
    }
    .form-control {
      background: transparent;
      color: #fff;
    }
    .rdt_Table > div {
      background: transparent;
      color: #fff;
      // padding: 10px 0px;
    }
  }
}

.dark {
  .react-toggle {
    & + span {
      color: #fff;
    }
  }
}
.dark {
  .input-group-text {
    background: transparent;
    color: #fff;
  }
}
