.sorting {
  display: flex;
  align-items: center;
  // column-gap: 15px;
  width: 100%;
  &>div {
    width: 100%;
  }
  b {
    margin-right: 15px;
    display: block;
  }
  //   margin-bottom: 10px;
  .sorting__item {
    // padding: 5px 15px;
    // border: 1px solid #303c54;
    // border-radius: 22px;
    // // background: rgba(#303c54, 0.05);
    cursor: pointer;
  }
  .icon {
    transition: 0.2s;
  }
  .icon.revert {
    transform: scaleY(-1);
  }
}

.admin .sorting {
  .sorting__item {
    // border: 1px solid #305052;
    // background: rgba(#305052, 0.05);
  }
}
.sorting {
  @media (max-width: 991px) {
    flex-wrap: wrap;
    row-gap: 5px;
    column-gap: 5px;
    b {
      width: 100%;
      margin-bottom: 5px;
    }
  }

  @media (max-width: 768px) {
    &>div {
      width: 100%;
    }
  }
}
