.admin .filters {
  width: 100%;

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.filters-wrapper__items {
  background: transparent;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 230px));
  column-gap: 15px;
  row-gap: 15px;
  // margin-bottom: 20px;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
  h6 {
    margin-bottom: 10px !important;
    padding-bottom: 0 !important;
    min-height: unset !important;
  }

  .form-select {
    background-color: #f4f6fa !important;
    border: none;
    border-radius: 10px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 10px 14px 11px 14px;
  }

  .form-label {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #051229;
  }
  span[class*="-indicatorSeparator"] {
    display: none !important;
  }
  div[class*="-control"] {
    border-radius: 10px;
    border: none;
    background: #f4f6fa;
    color: #5d6981;

    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;
  }
  div[class*="-menu"] {
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    background: #f4f6fa;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;
  }

  // margin-bottom: 20px;
  input.form-control {
    background: #f4f6fa;
    border: none;
    box-shadow: none;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10.5px 15px 10.5px 10px;
    color: #5d6981;

    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  input:-internal-autofill-selected {
    background-color: #f4f6fa !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }

  .input-group-text {
    border: none;
    box-shadow: none;
    background: #f4f6fa !important;
    padding-right: 5px;
    padding-left: 14px;
    border-radius: 10px;
  }

  input:auto-fill {
  }

  input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: -internal-light-dark(
      rgb(232, 240, 254),
      rgba(70, 90, 126, 0.4)
    ) !important;
    color: fieldtext !important;
  }
}

.react-datepicker__input-container {
  input {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    width: 100%;
    padding: 0.35rem 2.25rem 0.37rem 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid var(--cui-form-select-border-color, #b1b7c1);
    box-shadow: none;
  }
}

.date-picker {
  position: relative;

  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.filters-wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  row-gap: 15px;
}

.sorting {
  // width: 100%;
  // justify-content: flex-end;
}

.filters-mobile {
  display: none;
}

@media (max-width: 991px) {
  .filters-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .dark {
    .filters-wrapper {
      background: #303c54;
    }

    .sorting__item {
      border-color: #fff !important;
    }
  }
  .admin.dark {
    .filters-wrapper {
      background: #305052;
    }
  }
  .filters-wrapper {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    border-radius: 24px 24px 0px 0px !important;
    background: #fff;
    z-index: 9;
    // min-height: 50%;
    max-height: 80%;
    transition: 0.3s;
    box-shadow: none;
    padding: 30px 15px;
    margin-bottom: 0;

    .filters-close {
      position: absolute;
      right: 15px;
      top: 30px;
      z-index: 3;
    }

    &.active {
      bottom: 0;
    }

    .filters .filters__items {
      grid-template-columns: 1fr;
      row-gap: 10px;
    }

    .filters {
      position: relative;
    }

    .sorting {
      position: relative;
    }
  }
  .filters-plug {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
}

.dark {
  .filters__items {
    div[class*="-control"] {
      background: transparent;
    }

    div[class*="-singleValue"] {
      color: #fff;
    }

    div[class*="-menu"] {
      background: #282738;
    }
  }

  &.admin {
    .filters__items {
      div[class*="-menu"] {
        background: #305052;
      }
    }
  }
}

.react-datepicker__close-icon {
  right: 25px !important;

  &:after {
    background-color: #8c589e !important;
  }
}

.dark .filters__items div[class*="-control"] input {
  color: #fff !important;
}

.light-grey-block {
  .filters-wrapper__items {
    .form-select {
      background-color: #e7ebf2 !important;
    }
    input.form-control {
      background: #e7ebf2;
    }
  }
}
.light-grey-block .grey-block {
  .filters-wrapper__items {
    .form-select {
      background-color: #f4f6fa !important;
    }
    input.form-control {
      background: #f4f6fa;
    }
  }
}
.filters-wrapper__items {
  margin-bottom: 38px;
}
