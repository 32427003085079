div.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 10px;
}

div.btn-container i {
  display: inline-block;
  position: relative;
  top: -9px;
}

label {
  font-size: 13px;
  color: #424242;
  font-weight: 500;
  margin: 0 auto;
}

.btn-color-mode-switch {
  display: inline-block;
  margin: 0px;
  position: relative;
  width: 100%;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner {
  margin: 0px;
  width: 100%;
  height: 30px;
  background: #ecedef;
  border-radius: 26px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
  content: attr(data-on);
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  top: 7px;
  right: 20px;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
  content: attr(data-off);
  width: 50%;
  height: calc(100% - 4px);
  background: #fff;
  border-radius: 26px;
  position: absolute;
  left: 2px;
  top: 2px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 6px -2px #111;
  padding: 4px 0px 5px 0px;
}
.sun,
.moon {
  position: absolute;
}
.sun {
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
}
.moon {
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}
.btn-color-mode-switch > .alert {
  display: none;
  background: #ff9800;
  border: none;
  color: #fff;
}

.btn-color-mode-switch input[type="checkbox"] {
  cursor: pointer;
  width: 50px;
  height: 25px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
}
.btn-color-mode-switch input[type="checkbox"]:checked + label {
  .moon {
    color: lightblue !important;
    z-index: 3;
  }
  .sun {
    color: yellow !important;
    z-index: 3;
  }
}
.btn-color-mode-switch input[type="checkbox"] + label {
  .moon {
    color: blue !important;
    z-index: 3;
  }
  .sun {
    color: orange !important;
    z-index: 3;
  }
}
.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner:after {
  content: attr(data-on);
  left: 115px;
  background: rgba(0, 0, 0, 1);
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner:before {
  content: attr(data-off);
  right: auto;
  left: 20px;
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner {
  /*background: #66BB6A; */
  /*color: #fff;*/
}

.btn-color-mode-switch input[type="checkbox"]:checked ~ .alert {
  display: block;
}

/*mode preview*/
.dark-preview {
  background: #0d0d0d;
}

.dark-preview div.btn-container i.fa-sun-o {
  color: #777;
}

.dark-preview div.btn-container i.fa-moon-o {
  color: #fff;
  text-shadow: 0px 0px 11px #fff;
}

.white-preview {
  background: #fff;
}

.white-preview div.btn-container i.fa-sun-o {
  color: #ffa500;
  text-shadow: 0px 0px 16px #ffa500;
}

.white-preview div.btn-container i.fa-moon-o {
  color: #777;
}

p.by {
}

p.by a {
  text-decoration: none;
  color: #000;
}

.dark-preview p.by a {
  color: #777;
}

.white-preview p.by a {
  color: #000;
}

.expanded {
  @media (min-width: 992px) {
    .btn-color-mode-switch > label.btn-color-mode-switch-inner {
      width: 70px;
    }
    div.btn-container {
      padding: 0 5px;
    }
    .sun {
      left: 10px;
    }
    .moon {
      right: 10px;
    }
    .btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
      width: 30px;
    }
    .btn-color-mode-switch
      input[type="checkbox"]:checked
      + label.btn-color-mode-switch-inner:after {
      left: 38px;
    }
  }
}

.dark {
  div[class*="-Input"] {
    color: #fff;
  }
  div[class*="-option"] {
    color: #b263ce;
  }
}
