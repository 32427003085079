.container-panel {
  // margin-left: 266px !important;
  max-width: 100% !important;
}

@media (max-width: 768px) {
  .container {
    max-width: 100% !important;
    margin-left: 0 !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .container-panel {
    margin-left: 0 !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 100% !important;
  }
  .container-panel {
    padding-left: 0 !important;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 100% !important;
  }
  .container-panel {
    padding-left: 0 !important;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 100% !important;
  }
}
