.dark {
    .modal-header .btn-close {
        filter: invert(1);
    }
    .modal-content {
        background: #2d2d2d;
        color: #fff;
    }
}

.dark {
    .Toastify__toast-theme--light {
        background: #202020;
        color: #fff;
    }
    .Toastify__close-button {
        filter: invert(1);
    }
}