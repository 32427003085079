.notifier {
  width: 100%;
  //   margin-left: -12px;
  // margin-right: -10px;
  padding: 15px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  background: #1a212e !important;
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    width: 150px;
    height: 150px;
    // background: #3c4b64;
    
    position: absolute;
    left: -15px;
    top: calc(50% + 10px);
    z-index: 0;
    opacity: 0.5;
    border-radius: 50%;
    // filter: blur(25px);
    transform: translateY(-50%);
    animation: move 50s infinite;
  }
  &_error {
    &:before {
        background: #e24141;
    }
  }
  &_warning {
    &:before {
        background: #f4e200;
    }
  }
  &_info {
    &:before {
        background: #0045f4;
    }
  }
  .notifier__text {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    font-weight: 600;
    text-transform: uppercase;
  }
  &_error {
    // background-image: linear-gradient(to right, #ff0844 0%, #ffb199 100%);
  }

  @keyframes move {
    0% {
      left: -15px;
      filter: blur(25px);
      // background: #8c589e;
    }
    50% {
      left: calc(100% - 50px);
      filter: blur(20px);
      // background: #7195d1;
      // background: #3c6462;
    }
    100% {
      left: -15px;
      filter: blur(25px);
      // background: #8c589e;
    }
  }
}

.notifier__wrapper {
  display: grid;
  row-gap: 10px;
  // margin-left: -10px;
  padding-top: 10px;
  position: sticky;
  top: 0;
  z-index: 9;
    padding-bottom: 10px;
    background: #fff;
}
.dark {
    .notifier__wrapper {
     background: #303c54;   
    }
}