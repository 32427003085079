.contacts__row {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    a {
        display: flex;
        width: calc(30%);
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 50px;
        box-shadow: 0 0 6px rgba(0,0,0,.1);
        border-radius: 6px;
        font-size: 24px;
        text-decoration: none;
        svg {
            font-size: 3rem !important;
            width: 3rem !important;
            height: 3rem !important;
        }
    }
}