.password-info {
  margin-bottom: 15px;
  margin-top: 15px;

  .password__item {
    display: flex;
    align-items: center;
    font-size: 12px;
    column-gap: 11.5px;
    font-weight: 500;
    line-height: 12.63px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 10px 0px 10px 10px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background: #FA3333;
      height: 100%;
      width: 1px;
    }

    &.active {
      &:before {
        background: #50CB25
      }
    }

    svg {
      margin-right: 5px;
    }

    span {
      width: 100%;
      display: block;

      .question {


      }
    }
  }

  @media (max-width: 768px) {
    .password__item {
      align-items: flex-start;
      margin-bottom: 0;
      padding-top: 3px;
      padding-bottom: 3px;
      font-size: 12px;
      line-height: 14.5px;
      svg,img {
        width: 15px !important;
        // min-width: 15px !important;
        height: 15px !important;
      }
    }
  }
}
