@import "./assets/css/main.scss";
.page {
  min-height: 100vh;
  //display: flex;
  width: 100%;
  .container-panel {
    padding-bottom: 20px;
  }
  //background: #ecedef;
  & + .not-found {
    display: none;
  }
}
// .services {
//   & + .not-found {
//     display: none;
//   }
// }
// .content {
//   width: 100%;
// }

// .dark {
//   .page {
//     background: #282738;
//     color: #fff;
//   }
// }

// .dark-theme {
//   background: #202020;
// }

// .light-theme {
//   background: #ecedef;
// }

// .rdt_TableRow {
//   &:nth-child(even) {
//     background: rgba(0, 0, 0, 0.05);
//   }
// }
// .app.dark .page .shadow-block,
// .app.dark .page.container {
//   background: #303c54;
// }

// .app.dark.admin .page .container,
// .app.dark.admin .page .shadow-block {
//   background: #305052;
// }

// .rtf--ab {
//   background-color: #8c589e !important;
//   opacity: 0.8;
// }
// .rtf {
//   bottom: 100px !important;
//   right: 50px !important;
//   margin: 0 !important;
//   @media (max-width: 768px) {
//     bottom: 25px !important;
//     right: 25px !important;
//   }
// }

// .rtf.open .rtf--mb > * {
//   transform: none !important;
// }

// .rtf--mb {
//   animation: pulse 3s infinite;
//   @keyframes pulse {
//     0% {
//       transform: scale(0.95);
//       box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
//     }

//     70% {
//       transform: scale(1);
//       box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
//     }

//     100% {
//       transform: scale(0.95);
//       box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
//     }
//   }
// }

// .cookie {
//   position: fixed;
//   max-width: 500px;
//   width: 100%;
//   z-index: 5;
//   bottom: 20px;
//   left: 50%;
//   transform: translateX(-50%);
//   color: #fff;
//   background: #303c54;

//   .btn-close {
//     position: absolute;
//     right: 10px;
//     top: 10px;
//     filter: brightness(0.1) invert(1);
//   }
//   .cookie__footer {
//     display: flex;
//     justify-content: flex-end;
//           margin-top: 5px;

//   }

//   @media (max-width: 768px) {
//     h6 {
//       font-size: 16px;
//     }
//     font-size: 14px;
//     bottom: 70px;
//   }
// }

.container-panel {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.modal-content {
  border-radius: 10px;

  max-width: 470px;
}
