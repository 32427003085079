.partners-wrapper {
  position: relative;
  .accordion {
    margin-top: 20px;
  }
}
.partners__block {
  position: relative;
  .accordion-header {
    position: absolute;
    right: 0px;
    top: 25px;
    border: none !important;
    box-shadow: none !important;
    &:focus,
    &:active {
      border: none !important;
      box-shadow: none !important;
    }

    @media (max-width: 768px) {
      top: 10px;
    }
  }
  .accordion-button {
    border: none !important;
    box-shadow: none !important;
    &:focus,
    &:active {
      border: none !important;
      box-shadow: none !important;
    }
  }
}
.partners {
  // margin: 20px 20px;
  .partners__header {
    .partners__body-button {
      // right: -37px;
      padding: 7px 11px 7px 11px;
    }

    @media (max-width: 768px) {
      input[type="number"] {
        min-width: unset !important;
        height: 32px;
      }
      .partners__body-button {
        padding: 4px 11px;
      }
    }
  }
  @media (max-width: 768px) {
    margin: 0;
    margin-left: -10px;
    margin-right: -10px;
  }
}
.partners__block {
  border-radius: 10px !important;
  padding: 21px 16px;
  .form-label {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #051229;
  }
  input.form-control {
    background: #f4f6fa;
    border: none;
    box-shadow: none;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    padding: 10.5px 15px 10.5px 10px;
    color: #5d6981;

    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  input:-internal-autofill-selected {
    background-color: #f4f6fa !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }

  .input-group-text {
    border: none;
    box-shadow: none;
    background: #f4f6fa !important;
    padding-right: 5px;
    padding-left: 14px;
    border-radius: 10px;
  }

  input:auto-fill {
  }

  input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: -internal-light-dark(
      rgb(232, 240, 254),
      rgba(70, 90, 126, 0.4)
    ) !important;
    color: fieldtext !important;
  }
  .primary {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    padding: 10px 16px;
  }
  @media (max-width: 768px) {
    padding: 10px 5px;
    border-radius: 5px;
  }
  .partners__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px);

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 10px;

      .partners__header-end {
        width: 100%;
      }
    }
  }

  .partners__header-end {
  }

  .partners__header-start {
    display: grid;

    align-items: center;
    column-gap: 10px;
    grid-template-columns: 160px min-content 28px 170px;
    label {
      color: #fff !important;
    }
    .btn {
      width: 100%;
    }
    span {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 14px;
      line-height: 17.07px;
      letter-spacing: -0.5px;
      color: #051229;
      text-transform: capitalize;
    }

    @media (max-width: 768px) {
      grid-template-columns: 180px 80px 34px;
      justify-content: flex-start;
      .btn {
        font-size: 12px;
        padding-left: 5px !important;
        padding-right: 5px !important;
        width: unset;
      }
    }
  }
  .partners__body {
    margin-top: 20px;
    display: grid;
    column-gap: 20px;
    grid-template-columns: repeat(auto-fill, calc(33% - 10px));
    justify-content: space-between;
    row-gap: 5px;

    @media (max-width: 1450px) {
      grid-template-columns: repeat(auto-fill, calc(50% - 10px));
    }
    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
  }
  .partners__body-item {
    display: grid;
    align-items: center;
    border-radius: 6px;
    padding-left: 15px;
    grid-template-columns: 16px 22px 1fr 100px;
    column-gap: 10px;
    justify-content: space-between;
    // border: 1px solid #8c589e;
    background: #f4f6fa;
    position: relative;
    @media (max-width: 768px) {
      grid-template-columns: 16px 16px 1fr 100px;
      column-gap: 5px;
    }
    &:after {
      content: "%";
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      border-left: #b1b7c1;
      font-weight: 500;
    }
    .auth-notice {
      span {
        background: #e7ebf2;
      }
    }
    input[type="number"] {
      background-color: transparent;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top: 0;
      border-right: 0;
      text-align: center;
      font-size: 14px;
      padding-right: 30px;
      border-bottom: 0;
      min-width: 100px !important;
      &:focus,
      &:active {
        box-shadow: none;
        outline: none;
      }

      @media (max-width: 768px) {
        min-width: unset !important;
      }
    }
    span {
      line-height: 15px;
      font-size: 14px;
      text-transform: capitalize;

      @media (max-width: 768px) {
        font-size: 13px;
      }
    }
    .input-group {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
    .form-check-input {
      margin-top: 0;
    }
  }
  .flag {
    transform: scale(0.7);
    border-radius: 10px;
    margin-left: -6px;
    margin-right: 10px;
    @media (max-width: 768px) {
      // transform: unset;
      // margin-left: 0;
      // margin-right: 0;
      // margin-left: 5px;
      // margin-right: 15px !important;
    }
  }
  .partners__body-button {
    position: absolute;

    right: 4px;
    top: 3px;
    padding: 4px 8px !important;
    background-color: #8161eb;
    border: none;
    box-shadow: none;
    z-index: 3;
    border-radius: 10px !important;
    svg {
      color: #fff;
    }
    @media (max-width: 768px) {
      padding: 1px 6px !important;
    }
  }
}

.accordion-item {
  border: none;
  border-radius: 10px !important;
  overflow: hidden;
  margin-bottom: 30px;
}

.user__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .user__buttons {
    font-size: 16px !important;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    font-size: 16px;
    row-gap: 5px;
  }
}

.page.expanded {
  .partners__block {
    .partners__body {
      grid-template-columns: repeat(auto-fill, calc(24% - 10px));

      @media (max-width: 1600px) {
        grid-template-columns: repeat(auto-fill, calc(33% - 10px));
      }
      @media (max-width: 1250px) {
        grid-template-columns: repeat(auto-fill, calc(50% - 10px));
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }
  }
}
.dark {
  .accordion-item {
    background: transparent !important;
  }
  .partners__block {
    box-shadow: 6px 6px 14px 0 rgba(0, 0, 0, 0.2),
      -8px -8px 18px 0 rgba(0, 0, 0, 0.55);
  }
}

.user-heading {
  .primary {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    padding: 10px 15px;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    flex-direction: column !important;
    row-gap: 18px;

    .primary {
      width: 100%;
      text-align: center;
    }
  }
}

.admin-panel__item {
  .user-form__wrapper {
    .user-form {
      display: grid;
      grid-template-columns: repeat(auto-fill, 227px);
      column-gap: 16px;
      row-gap: 20px;

      @media (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
      }
      @media (max-width: 768px) {
      }
      @media (max-width: 450px) {
        grid-template-columns: 1fr;
      }
    }
  }
  .user-form {
    .toggler {
      display: flex;
      row-gap: 15px;
      span {
        font-family: Montserrat;
        font-weight: 700;
        font-size: 13.5px;
        line-height: 17.07px;
        letter-spacing: -0.5px;
        // text-transform: lowercase;
        margin-bottom: 0;
      }
      flex-direction: column-reverse;
      align-items: flex-start;
      width: 100%;
    }
    &_togglers {
      display: grid;
      grid-template-columns: repeat(auto-fill, 227px);
      column-gap: 16px;
      row-gap: 20px;
      @media (max-width: 991px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media (max-width: 550px) {
        grid-template-columns: 1fr 1fr;
      }
      @media (max-width: 450px) {
        grid-template-columns: 1fr;
      }
    }
  }
}
