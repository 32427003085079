.coupons-form {
  display: flex;
  width: 100%;
  align-items: flex-end;
  column-gap: 15px;
  justify-content: space-between;
  & > div {
    display: flex;
    column-gap: 15px;

    @media (max-width: 768px) {
      flex-direction: column;
      row-gap: 20px;
    }
  }
  .primary {
    padding: 10px 14px 11px 14px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    .primary {
      width: 100%;
      margin-top: 20px;
    }

    input,
    select {
      width: 100%;
    }
    & > div {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
.rdt_TableCell input[type="checkbox"] {
  margin-left: 18px;
}
.position-relative {
  .delete-button {
    position: absolute;
    left: 0;
    z-index: 3;
    bottom: -50px;
    padding: 10px 16px;
    border-radius: 10px;
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}
.mobile-table__item_check {
  .mobile-table__row {
    &_0 {
      padding-left: 25px;
    }
  }
  input[type="checkbox"] {
    position: absolute;
    left: 9px;
    top: 11px;
    &[disabled] {
      opacity: 0.5;
    }
  }
}
.panel__content {
  padding-top: 15px;
  @media (max-width: 768px) {
    overflow: visible;
  }
}
.mobile-table {
  @media (max-width: 768px) {
    padding: 5px;
    width: calc(100% + 36px);
    margin-left: -18px;
  }
}

.delete-button {
  @media (max-width: 768px) {
    position: fixed;
    top: 60px;
    z-index: 10;
    right: 10px;
    color: #fff;
    width: calc(100% - 20px);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
  }
}
.tooltip-row {
  @media (max-width: 991px) {
    display: flex;
    column-gap: 5px;
    justify-content: center;
    align-items: center;
  }
}

.rdt_TableCell,
.rdt_TableCol {
  .form-check-input {
    border-radius: 5px !important;
    background: #8161eb;
    border: none !important;
    &:checked {
      & + span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        // left: 50%;
        // top: 50%;
        top: 5.5px;
        margin-left: -7.5px;
        transform: translate(-50%);
        background-color: #8161eb;
        pointer-events: none;
      }
    }
  }
}
