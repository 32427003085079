.preloader-block {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 11px;
  align-self: flex-start;
  .text-primary {
    color: #8161eb !important;
  }
  b {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .dots {
    display: inline-block;

    span {
        opacity: 0;

      &:first-child {
        animation: 1.6s showDots infinite;
        animation-delay: 0s;
      }
      &:nth-child(2) {
        animation: 1.6s showDots infinite;
        animation-delay: 0.4s;
      }
      &:last-child {
        animation: 1.6s showDots infinite;
        animation-delay: 0.8s;
      }
    }
  }
}
@keyframes showDots {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
