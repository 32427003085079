.rules-block {
    white-space: pre-wrap;
    background: #F4F6FA;
    border-radius: 10px;
    padding: 15px;
    color: #051229;
    overflow-y: auto;
    // margin-bottom: 20px;
    h2 {
        font-size: 20px;
        font-weight: 700;
        line-height: 24.38px;
        letter-spacing: -0.5px;
        text-align: left;

    }
    div {
        font-size: 13px;
        font-weight: 500;
        line-height: 15.85px;
        letter-spacing: -0.5px;
        text-align: left;

    }
}

.light-grey-block {
    .rules-block {
        padding: 0;

    }
}