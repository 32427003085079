h3.page__title {
    letter-spacing: 0.04em;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 24px;
}

h4.page__subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 24px;
}