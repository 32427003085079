body {
  .rdt_Table {
    background: transparent;
  }

  .rdt_TableHeadRow {
    background: transparent;
    min-height: unset;
    border-bottom: 0;

    .rdt_TableCol {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 700;
      line-height: 17.07px;
      letter-spacing: -0.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      padding-top: 8px;
      padding-bottom: 8px;
      color: #051229;

    }
  }

  .rdt_TableBody {
    background: transparent;

    .rdt_TableRow {
      background: #F4F6FA;
      min-height: unset;
      padding: 8px 0px;
      border-radius: 10px;
      margin-bottom: 6px;
      border-bottom: 0;

      .rdt_TableCell {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.07px;
        letter-spacing: -0.5px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #051229;
      }
    }
  }


}

