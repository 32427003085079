.dark {
    &:root {
        color-scheme: dark
    }
    .rdt_TableHeadRow {
        border-color: #fff;
        background: transparent;
        color: #fff !important;
    }
    .rdt_TableRow,
    .rdt_Table,
    .rdt_Pagination {
        color: #fff;
        background: transparent;
    }
    .rdt_Pagination button {
        filter: brightness(0) invert(1);
    }
    .rdt_TableCell {
        .copy-btn {
            color: #fff;
        }
    }
    .rdt_TableRow {
        &:hover {
            background-color: rgba(0, 0, 0, .2);
            outline-color: rgba(0, 0, 0, .2);
            color: #fff;
        }
    }
}