.btn-close {
  display: none;
}

.modal-close {
  background: #fa3333;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-self: center;
  border-radius: 50%;
  position: absolute;
  right: -12px;
  top: -12px;
  cursor: pointer;
}

.modal-footer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 0;
  padding: 15px;
  column-gap: 15px;

  .primary {
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    color: #fff;
    padding: 10px 17px;
    &[disabled] {
      opacity: 0.7;
      cursor: not-allowed !important;
    }
  }
}

.modal-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
}

.modal-content {
  border: none;
}

.modal-backdrop.show {
  background: #130933d9;
  opacity: 0.85;
}

.modal-body {
  padding-top: 0;
  padding-bottom: 0;
}

.modal-logo {
  margin-bottom: 24px;
  width: 159px;
  height: 31px;
}

.modal-title {
  color: #051229;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.26px;
  letter-spacing: -0.5px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.modal-link {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  letter-spacing: -0.5px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  color: #8161eb;
  cursor: pointer;
  text-align: right;
  &:hover {
    opacity: 0.7;
  }
}

label.auth-notice {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.63px;
  letter-spacing: -0.5px;
  width: 100%;
  cursor: pointer;
  user-select: none;
  // display: flex;
  text-align: center;
  // justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    display: none;
  }
  @media (max-width: 768px) {
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  span {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background: #f4f6fa;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    &:before {
      content: "";
      width: 6px;
      height: 6px;
      position: absolute;
      left: 50%;
      border-radius: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: #8161eb;
      pointer-events: none;
      transition: 0.2s;
      opacity: 0;
    }
    &.checked {
      &:before {
        opacity: 1;
      }
    }
  }

  a {
    color: #8161eb;
  }
}
.modal-body {
  input.form-control {
    background: #f4f6fa;
    border: none;
    box-shadow: none;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10.5px 15px 10.5px 5px;
    color: #5d6981;

    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  input:-internal-autofill-selected {
    background-color: #f4f6fa !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }

  .input-group-text {
    border: none;
    box-shadow: none;
    background: #f4f6fa !important;
    padding-right: 5px;
    padding-left: 14px;
    border-radius: 10px;
  }

  input:auto-fill {
  }

  input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: -internal-light-dark(
      rgb(232, 240, 254),
      rgba(70, 90, 126, 0.4)
    ) !important;
    color: fieldtext !important;
  }
}

.message {
  padding: 9px 15px 3px 15px;
  border-radius: 10px;

  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.5px;
  text-align: left;
  margin-bottom: 13px;

  &_warning {
    background: #fbf3df;
    color: #faab33;
  }
}

.generate-password {
  // position: absolute;
  // right: 14px;
  // top: 50%;
  background: #f4f6fa !important;
  // transform: translateY(-50%);
  margin-right: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  letter-spacing: -0.5px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  cursor: pointer;
  z-index: 3;
  color: #8161eb;
}
