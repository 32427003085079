.promocode {
  .promocode__inner {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin: 0 auto;
    padding: 15px 15px 15px 0px;
  }

  // box-shadow: inset 2px 2px 5px rgba(154, 147, 140, 0.5), 1px 1px 5px rgba(255, 255, 255, 1);
  input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  @media (max-width: 768px) {
    border-bottom: 0;
    width: 100%;

    .promocode__inner {
      padding-right: 0;
      width: 100%;
      justify-content: flex-start;
    }
  }
}
.add-tabs__button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    margin-bottom: 0;
    font-weight: 300;
    text-align: left;
  }
}
.add-tab {
  // @media (max-width: 768px) {
  display: none !important;

  &.active {
    display: block !important;
  }
  // }
}
.add-tabs {
  // display: none;

  @media (max-width: 768px) {
    overflow-x: auto;
    margin-left: -10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: -10px;
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
  display: flex;
  justify-content: flex-start;
  column-gap: 10px;
  // border-bottom: 1px solid #cacaca;
  button {
    background: none;
    // border: none;
    border: 1px solid #cacaca;
    border-radius: 16px;
    position: relative;
    font-size: 14px;
    padding: 5px 15px;
    font-weight: 500;
    transition: 0.2s;
    &:hover {
      background: rgba(#7a3f92, 0.3);
    }
    &.active {
      background: #7a3f92;
      color: #fff;
    }
  }
  // }
}
.add-pay {
  position: relative;
  width: 100%;
  margin-top: 10px;
  .add-pay__content {
    display: grid;
    // grid-template-columns: auto 300px 1fr;
    margin-top: 30px;
    column-gap: 10px;
  }
  .add-pay__items {
    display: grid;
    grid-template-columns: repeat(auto-fill, calc(20% - 20px));
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 1500px) {
      grid-template-columns: repeat(auto-fill, calc(25% - 20px));
    }

    @media (max-width: 991px) {
      grid-template-columns: repeat(auto-fill, calc(50% - 10px));
    }
    @media (max-width: 550px) {
      grid-template-columns: repeat(auto-fill, 100%);
    }
    column-gap: 20px;
    row-gap: 20px;
    // padding-bottom: 120px;
  }
  .add-pay__item {
    .input-group-text {
      border-bottom: 0;
    }
    // margin-bottom: 10px;
    padding: 10px;
    // border: 1px solid rgba(0, 0, 0, 0.3);
    // box-shadow: 0 0 6px rgba(#252523,0.2);
    // box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px, rgba(0, 0, 0, 0.1) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
    border-radius: 6px !important;
    overflow: hidden;
    cursor: pointer;
    button {
      width: 100%;
    }
    .add-pay__item-form {
      height: 0px;
      opacity: 0;
      overflow: hidden;
      padding-top: 10px;
      margin-top: 10px;
      transition: 0.2s;
      //   border-top: 1px solid rgba(0, 0, 0, 0.1);

      input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        text-align: center;
        margin-left: -35px !important;
        &:focus,
        &:active {
          outline: none;
          box-shadow: none;
          background: transparent;
        }
      }
      button {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    &.active {
      .add-pay__item-form {
        height: 87px;
        opacity: 1;
        overflow: hidden;
      }
    }
    .add-pay__item-currency {
      margin-left: -10px;
      margin-right: -10px;
      margin-top: -10px;
      padding: 10px;
      display: flex;
      position: relative;
      justify-content: center;
      font-weight: 600;
      color: #7a3f92;
      //   background-color: #fff;
      margin-bottom: 10px;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      // background: rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      svg {
        transition: 0.2s;
        opacity: 0;
      }
    }
    .add-pay__item-check {
      position: absolute;
      right: 10px;
    }
    &.active {
      .add-pay__item-currency {
        // background: #8c589e;
        // background: #7a3f92;
        color: #fff;
        background: #1a212e !important;
        border-radius: 12px;
        position: relative;

        overflow: hidden;
        &:before {
          content: "";
          width: 80px;
          height: 80px;
          // background: #3c4b64;
          background: #8c589e;
          position: absolute;
          left: -15px;
          top: calc(50% + 10px);
          z-index: 0;
          border-radius: 50%;
          // filter: blur(25px);
          transform: translateY(-50%);
          animation: move 10s infinite;
        }
        div {
          position: relative;
        }

        @keyframes move {
          0% {
            left: -15px;
            filter: blur(25px);
            // background: #8c589e;
          }
          50% {
            left: calc(100% - 50px);
            filter: blur(20px);
            // background: #7195d1;
            // background: #3c6462;
          }
          100% {
            left: -15px;
            filter: blur(25px);
            // background: #8c589e;
          }
        }

        svg {
          opacity: 1;
        }
        svg,
        svg * {
          fill: #fff;
          stroke: #fff;
        }
      }
      &:hover {
        .add-pay__item-currency {
          // background: #8c589e;
          svg {
            opacity: 1;
          }
        }
      }
    }
    &:hover {
      .add-pay__item-currency {
        // background: rgba(#8c589e, 0.2);
        // background: #7a3f92;
        svg {
          opacity: 0.3;
        }
      }
    }
  }
  .add-pay__item-conditions {
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 14px;
  }
  .add-pay__form.shadow-block {
    height: auto;
    align-self: flex-start;
    padding: 26px;
    position: fixed;
    background: #636f83;
    width: 500px;
    bottom: 107px;
    height: 112px;
    // right: 0;
    right: 45px;
    // transform: translateX(-50%);
    label {
      color: #fff;
    }
    p {
      margin-bottom: 0;
      color: #fff;
    }
  }
  .add-pay__item {
    input,
    .input-group-text {
      color: #252523;
      border-color: #252523 !important;
      background: transparent;
    }
  }
}
.dark {
  .add-pay .add-pay__item {
    .add-pay__item-currency {
      // color: #fff;
      background: rgba(0, 0, 0, 0.2);
    }
    input,
    .input-group-text {
      color: #fff;
      border-color: #1a212e !important;
    }
  }
}

.input-group-text {
  background: transparent;
}

.promocode__rate {
  font-weight: 800;
}
.lava__footer {
  display: flex;
  align-items: center;
  column-gap: 20px;
  img {
    width: 75px
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}