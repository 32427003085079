.work {
    padding: 100px 0px;
    background: linear-gradient(to right, #9f5695, #3c094a);
    h3 {
        text-align: center;
        color: #fff;

        @media (max-width: 768px) {
            font-size: 20px;
        }
    }
    p {
        text-align: center;
        font-size: 20px;
        color: #fff;

        @media (max-width: 768px) {
            font-size: 18px;
            font-weight: 300;
        }
    }
    .work__content-row {
        display: flex;
        justify-content: space-around;
        margin-top: 30px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            row-gap: 15px;
        }
    }
    .work__content-item {
        background: rgba(255, 255, 255, 0.7);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(18.1px);
        -webkit-backdrop-filter: blur(18.1px);
        border: 1px solid rgba(255, 255, 255, 0.78);
        display: flex;
        flex-direction: column;
        align-items: center;
        svg * {
            fill: #7a3f93;
            stroke: #7a3f93;
            
        }
        svg {
            @media (max-width: 768px) {
                // width: 150px;
                width: 50px;
                height: auto;
                margin: 0 auto;
            }
        }
        color: #7a3f93;
        padding: 15px;
        width: 250px;
        span {
            text-align: center;
            display: block;
            margin-top: 10px;
            font-size: 18px;

            @media (max-width: 768px) {
                font-size: 16px;
                text-align: left;
            }
        }

        @media (max-width: 768px) {
            width: 100%;
            display: grid;
            grid-template-columns: 70px 1fr;
        }
    }

    @media (max-width: 768px) {
        padding: 25px 0px;
    }
}