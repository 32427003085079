.not-found {
    min-height: 100vh;
    background: #fff;
    img {
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
        display: flex;
    }
    .not-found__buttons {
        display: flex;
        justify-content: center;
        column-gap: 20px;
        .btn {
            min-width: 150px;
        }
    }
}