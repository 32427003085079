.country-select {
  position: relative;
  // min-height: 100px;
  &.active {
    .country-select__icon {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .country-select__value {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    height: 100%;

    // border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    padding: 20px 10px 10px 10px;
    min-height: 94px;
    // padding-top: 20px;
    .flag {
      transform: scale(2);
      margin: 0 auto;
    }
    p {
      transition: 0.2s;

      border-radius: 15px;
      cursor: pointer;
      padding: 0 10px;
      // &:hover {
        // background: rgba(0, 0, 0, 0.05);
      // }
    }
    p {
      grid-template-columns: 1fr;
      display: grid;
      align-items: center;
      grid-template-rows: 60px auto;
      column-gap: 5px;
      margin-bottom: 0;
      max-width: 250px;
      align-items: center;
    }

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: -10px;
    }
    svg {
      transition: 0.3s;
      cursor: pointer;
      position: absolute;
      left: 5px;
      top: 5px;
      font-size: 22px !important;
      width: 22px !important;
      height: 22px !important;
      color: #8c589e;
    }
    svg * {
      cursor: pointer;
      transition: 0.3s;
    }
    .country-select__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 5px;
      top: 5px;
      text-decoration: underline;
      color: #8c589e;
      font-size: 14px;
    }
  }
  .country-select__list {
    margin-top: 3px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    position: absolute;
    background: #fff;
    z-index: 99;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    width: 220px;
    .country-select__item {
      padding: 5px 10px;
      position: relative;
      user-select: none;
      cursor: pointer;
      .country-select__list {
        display: none;
        transform: translateX(100%);
      }
      &:hover {
        background: rgba(0, 0, 0, 0.1);
        .country-select__list {
          margin-top: -30px;
          display: block;
          margin-left: -32px;
          max-height: 300px;
          overflow-y: auto;
          width: 240px;
          .country-select__item {
          }
        }
      }
    }
  }
}

.choose-countries {
  padding: 15px;
  box-shadow: 0 0 4px 1px rgba(0,0,0,0.1) inset;
  // box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
  row-gap: 12px;

  @media (max-width: 1400px) {
    margin-bottom: 0;
  }
}

.dark {
  .country-select {
    .country-select__list {
      background: #303c54;
    }
  }
}

.country-select_choose {
  cursor: pointer;
}
.country-select_choosed {
  background: #fcf2ff
}
.dark {
  .country-select_choosed {
    background: #50335852
  } 

}