.rti--tag {
  background: #50cb25 !important;
  color: #fff;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.07px;
  button {
    filter: invert(1);
  }
}

.ips-interval {
  display: flex;
  align-items: flex-end;
  column-gap: 15px;

  @media (max-width: 1400px) {
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    & + .d-flex {
      flex-wrap: wrap;
      row-gap: 10px;
    }
    flex-direction: column;
    align-items: flex-start;
    .col,
    .col-3 {
      width: 100%;
    }
  }
}

.ips-separator {
  margin-bottom: 10px;
  display: block;
  @media (max-width: 768px) {
    transform: rotate(90deg);
    margin: 0 auto;
    margin-bottom: -10px;
  }
}
.ips-buttons {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  column-gap: 10px;

  @media (max-width: 768px) {
    button {
      font-size: 12px;
      padding: 5px;
    }
  }
}
@media (max-width: 768px) {
  .rti--tag {
    max-width: 100%;
    span {
      word-break: break-word;
    }
  }
  .rti--input {
    // width: 100% !important;
    width: auto;
  }
}

.logs {
  .primary {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.07px;
    letter-spacing: -0.5px;

    padding: 10px 16px;
  }
  .rti--input {
    // width: 100%;
    padding: 10.5px 0px 10.5px 0px;
    background: #f4f6fa;
    border-radius: 10px;
  }
  .rti--tag {
    background: #50cb25;
  }
  .rti--container {
    border: none;
    background: #f4f6fa;
    padding: 0px 15px;
    box-shadow: none;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    color: #5d6981;

    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    flex-wrap: nowrap;
    overflow-x: auto;
    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }

    @media (max-width: 768px) {
      // padding-top: 5px;
      // padding-left: 5px;
    }
  }

  input:-internal-autofill-selected {
    background-color: #f4f6fa !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }

  .input-group-text {
    border: none;
    box-shadow: none;
    background: #f4f6fa !important;
    padding-right: 5px;
    padding-left: 14px;
    border-radius: 10px;
  }

  input:auto-fill {
  }

  input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: -internal-light-dark(
      rgb(232, 240, 254),
      rgba(70, 90, 126, 0.4)
    ) !important;
    color: fieldtext !important;
  }
}

.logs {
  .primary.outline {
    background: transparent;
    color: #8161eb;
    box-shadow: 0 0 0 1px #8161eb !important;
    &:hover {
      color: #fff;
    }
  }
}
.ips-separator {
  opacity: 0;
}
