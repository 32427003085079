.app {
  .columns {
    // padding: 20px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    grid-template-rows: 1fr;
    height: 100%;
    align-items: flex-start;

    &_md {
      // grid-template-columns:600px 1fr;
      grid-template-columns: 600px 1fr;
      column-gap: 52px;
      @media (max-width: 1400px) {
        grid-template-columns: 1fr;
      }
    }

    &_sm {
      grid-template-columns: 450px 1fr;

      @media (max-width: 1200px) {
        grid-template-columns: 450px 1fr;
        column-gap: 20px;
      }
      @media (max-width: 991px) {
        grid-template-columns: 1fr;
        row-gap: 30px;
      }

      // .services-table {
      //   @media (max-width: 768px) {
      //     margin-left: -7.5px;
      //   }
      // }
    }

    .shadow-block {
      padding: 0;
    }

    .columns__item {
      // height: 100%;
      align-self: flex-start;
      // display: grid;
      // grid-template-rows: auto auto auto;
      h6 {
        margin-bottom: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 40px;
      }

      // .rdt_Table {
      //     min-height: 400px;
      // }
    }
  }
}

.page .rdt_TableCell,
.rdt_TableHeadRow div {
  @media (max-width: 991px) {
    max-width: auto !important;
    min-width: 70px !important;
  }
}

// .services-table {
//   @media (max-width: 1400px) {
//     display: grid;
//     column-gap: 12px;
//     grid-template-columns: auto 1fr;

//     .inset-shadow h6 {
//       display: none !important;
//     }
//   }

//   @media (max-width: 1250px) {
//     grid-template-columns: 1fr;
//   }
//   @media (max-width: 991px) {
//     .inset-shadow h6 {
//       display: flex !important;
//     }
//   }

//   @media (max-width: 991px) {
//     display: grid;
//     grid-template-columns: 1fr;
//     .choose-countries {
//       width: 100%;
//       margin-bottom: 15px;
//     }
//     // .services-table {
//     //   width: 100%;
//     // }

//     @media (max-width: 768px) {
//       .choose-countries {
//         grid-template-columns: 1fr;
//         .country-select .country-select__value {
//           justify-content: flex-start;
//           align-items: flex-start;
//           padding: 10px 10px 10px 0px;
//           min-height: unset;
//           & > span {
//             width: calc(100% - 100px);
//           }
//           svg {
//             left: unset;
//             right: 5px;
//             top: unset;
//             bottom: 5px;
//           }
//           p {
//             grid-template-rows: 40px auto;
//             font-size: 14px;
//             justify-content: flex-start;
//           }
//           .flag {
//             transform: scale(1);
//             margin-left: unset;
//           }
//         }
//       }
//     }
//   }

//   .rdt_TableRow {
//     // padding: 15px 0px;
//     max-height: 50px;
//   }
// }
.sms-content {
  white-space: nowrap !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sms-content-wrapper {
  display: grid;
  grid-template-columns: 1fr 15px;
  align-items: center;
  column-gap: 5px;

  &:hover {
    .question {
      background: rgba(#252523, 0.3);
    }
  }
}

.question {
  display: flex;
  width: 15px;
  height: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #252523;

  transition: 0.3s;
}

.table-wrapper-block {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.dark {
  .mobile-table__item {
    box-shadow: 0 0 3px rgba(#fff, 0.3);

    .mobile-info {
      .icon {
        color: #fff;
      }
    }
  }
}

.mobile-table__item {
  width: 100%;
  position: relative;
  border-radius: 6px;
  padding: 5px;
  box-shadow: 0 0 3px rgba(#252523, 0.3);
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 5px;
  overflow: hidden;
  padding-right: 25px;

  &:hover {
    .mobile-info {
      transform: translateX(0);
    }
  }

  &_xs {
    padding-right: 25px;

    .mobile-table__row {
      .mobile-table__value {
        font-size: 10px;
      }

      .mobile-table__key {
        font-size: 10px;
      }
    }
  }

  .mobile-info-button.mobile-info {
    .btn {
      background: transparent !important;
      border: 0 !important;

      &:before {
        display: none;
      }
    }
  }

  .mobile-info {
    position: absolute;
    right: 0;
    // transform: translateX(100%);
    transition: 0.2s;
    height: 100%;
    border-radius: 0px 6px 6px 0px;
    // background: rgba(0, 0, 0, 0.5);
    background: #1a212e !important;
    // position: relative;
    // border-color: #1a212e !important;
    overflow: hidden;

    &_transparent {
      background: transparent !important;
      color: #252523;

      &:before {
        display: none;
      }
    }

    &:before {
      content: "";
      width: 40px;
      height: 40px;
      // background: #3c4b64;
      background: #8c589e;
      position: absolute;
      right: 0px;
      top: calc(50% + 15px);
      z-index: 0;
      border-radius: 50%;
      filter: blur(20px);
      transform: translateY(-50%);
      // animation: buttonMove 10s infinite;
    }

    @keyframes buttonMove {
      0% {
        left: -15px;
        filter: blur(25px);
        // background: #8c589e;
      }
      50% {
        left: calc(100% - 20px);
        filter: blur(20px);
        // background: #7195d1;
        // background: #3c6462;
      }
      100% {
        left: -15px;
        filter: blur(25px);
        // background: #8c589e;
      }
    }
    color: #fff;

    display: flex;
    padding: 0 5px;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    .btn-sm,
    .btn-group-sm > .btn {
      padding: 5px 0px 0px 0px;
      font-size: 0;
    }

    .question {
      border: 0;
      border-color: #fff !important;
    }
  }

  &_one {
    display: flex;
    justify-content: space-between;
    padding-right: 80px;

    .mobile-table__row {
      width: 100%;
    }

    .mobile-table__value {
      text-align: center;
      justify-content: center !important;
      display: flex;
    }
  }
}

.mobile-table__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px;
  // border-bottom: 1px solid #cacaca;
  font-size: 12px;

  &:last-child {
    border-bottom: 0;
  }

  // &:nth-last-child(1) {
  //   border-bottom: 0;
  // }
  .btn {
    // width: 15px;
    // height: 15px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // svg {
    //   width: 10px;
    //   height: 10px;font
    // }
  }

  .mobile-table__value {
    min-width: 50px;
    display: flex;
    justify-content: flex-end;
    text-align: right;

    &:empty {
      display: none;
    }
  }

  .mobile-table__key {
    display: flex;
    align-items: center;

    &:empty {
      display: none;
    }
  }

  .d-flex.align-items-center {
    height: 18px;
  }

  &:empty {
    display: none;
  }

  .btn-sm {
    padding: 0.3rem 0.8rem;
  }
}

.mobile-table {
  padding: 5px;

  .bg {
    transform: scale(0.5);
  }
}

h6.heading_with_select {
  display: flex;
  justify-content: space-between;
  // align-items: flex- !important;
  margin-bottom: 15px !important;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .heading__select {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 500px) {
      width: 100%;
      align-items: flex-start;
    }

    b {
      margin-bottom: 5px;

      @media (max-width: 500px) {
        font-size: 14px;
      }
    }
  }

  select {
    width: 100%;
  }
}

.dark {
  select {
    background-color: #303c54;
  }
}

.dark.admin {
  select {
    background-color: #305052;
  }
}

.hidden_sm {
  @media (max-width: 768px) {
    display: none;
  }
}

.hidden_md {
  @media (min-width: 769px) and (max-width: 991px) {
    display: none;
  }
}

// .services-table {
//   .rdt_TableBody {
//     max-height: 300px;
//     overflow-y: auto;
//   }
//   .mobile-table {
//     max-height: 300px;
//     overflow-y: auto;
//   }
// }

.service-block {
  display: grid;
  grid-template-columns: 30px 1fr;
  width: 100%;

  .service-block__info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    row-gap: 3px;
    align-items: flex-start;
    text-align: left;

    .service-block__title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    .service-block__description {
      color: #7b7b7b;
    }

    @media (max-width: 768px) {
      row-gap: 0;
    }
  }
}

.resend {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background: rgb(0, 198, 90);
  border-radius: 50%;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: 0.7;
  }

  svg {
    // transform: rotate(270deg)
  }
}

.orders-list {
  display: grid;
  row-gap: 13px;
}

.orders__item {
  background: #e7ebf2;
  border-radius: 10px;
  padding: 20px 15px 20px 13px;
  width: 100%;
  display: grid;
  grid-template-areas: "country phone . amount time action" "status status status status status status";
  grid-template-rows: 38px 38px;
  grid-template-columns: auto 210px 1fr auto auto auto;
  row-gap: 10px;
  //column-gap: 20px;

  .flag {
    border-radius: 2px;
    // transform: scale(1.4) scale(1.5) scaleX(0.7);
    margin-left: 10px;
  }

  .bg {
    transform: scale(0.65);
  }

  .manrope {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.12px;
    letter-spacing: -0.5px;
  }

  .orders__country {
    display: flex;
    align-items: center;
    grid-area: country;
    margin-right: 20px;
  }

  .orders__country-name {
    text-transform: capitalize;
    margin-left: 15px;
    color: #051229;
    font-size: 16px;
    line-height: 19.5px;
    letter-spacing: -0.5px;
    font-weight: 600;
  }

  .orders__time {
    grid-area: time;
  }

  .orders__amount {
    grid-area: amount;
  }

  .orders__action {
    grid-area: action;

    &:not(:empty) {
      margin-left: 20px;
    }

    button {
      padding: 10px 17px;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.07px;
      letter-spacing: -0.5px;
    }
  }

  .orders__status {
    grid-area: status;
  }

  .orders__time,
  .orders__amount {
    div {
      width: 100px;
    }
  }

  .orders__time,
  .orders__amount,
  .orders__status {
    div {
      padding: 11px;
      border-radius: 10px;
      background: #fff;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.07px;
      letter-spacing: -0.5px;
      color: #051229;
      display: flex;
      column-gap: 13px;

      &:not(:empty) {
        margin-left: 20px;
      }
    }
  }

  .orders__phone {
    grid-area: phone;
    color: #051229;
    font-size: 16px;
    line-height: 19.5px;
    letter-spacing: -0.5px;
    font-weight: 600;
    min-width: 210px;
    padding: 4px 4px 4px 15px;
    border-radius: 10px;
    background: #fff;
    margin-left: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
    &_empty {
      background: transparent;
    }
    button {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .orders__status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 38px;
    border-radius: 10px;
    background: #fff;
    padding: 0px 13px;
    column-gap: 15px;
    font-size: 14px;
    font-weight: 400;
    line-height: 12.07px;
    letter-spacing: -0.5px;

    span {
      // font-weight: 700;
      display: inline;
      margin-left: -10px;
    }
  }

  @media (max-width: 1300px) {
    grid-template-areas:
      "country country country" "phone amount time" "status status status"
      ". action .";
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    width: 100%;
    .orders__country {
      margin: 0 auto;
    }
    .orders__phone {
      margin-right: 0;
      // display: flex;
      // justify-content: flex-start;
    }
  }
  @media (max-width: 550px) {
    grid-template-areas: "country country country country" "phone phone phone phone" "amount amount time time" "status status status status" ". action action .";
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    column-gap: 10px;
    .orders__amount {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      div {
        width: 100%;
        margin-left: 0 !important;
        justify-content: center;
      }
    }
    .orders__time {
      display: flex;
      justify-content: flex-start;
      width: 100%;

      div {
        width: 100%;
        margin-left: 0 !important;
        justify-content: center;
        span {
          width: 35px;
        }
      }
    }
    .orders__phone {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
    }
    .orders__status {
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.rdt_TableCell,
.mobile-list__value {
  .error {
    padding: 10px 10px;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 14px;
    line-height: 17.07px;
    letter-spacing: -0.5px;
  }
}
