.table-pagination {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 31px;
    width: 100%;
    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 100%;
    }
    p {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.07px;
      letter-spacing: -0.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      white-space: nowrap;
    }
  }
  // &.show-all {
  //   grid-template-columns: auto 150px 200px;
  //   justify-content: space-between;
  // }
  .form-select {
    border-color: #8c589e;
    max-width: 170px;
  }
  .table-pagination__show-all {
    margin-left: 10px;
    cursor: pointer;
  }
  ul {
    padding-left: 0;
    list-style-type: none;
    display: flex;
    margin-bottom: 0;
    align-items: center;
    li {
      margin-right: 7px;
      transition: 0.2s;

      min-width: 33px;
      height: 33px;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.07px;
      letter-spacing: -0.5px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #051229;
      background: #e7ebf2;
      border: 1px solid transparent;

      @media (max-width: 450px) {
        min-width: 25px;
        a {
          padding: 4px 4px !important;
        }
      }
      a {
        padding: 6px 12px;
      }
      &.disabled {
        opacity: 0.4;
      }
      &.selected {
        background: #f4f6fa;
        border-color: #a8b7d1;
      }
      &.next,
      &.previous {
        a {
          text-decoration: none;
          color: #051229;
          font-family: "Manrope", serif;
          font-optical-sizing: auto;
        }
      }
      &.previous {
        border-radius: 10px;
      }
      &.next {
        border-radius: 10px;
      }
      &:hover {
        // color: #fff;
        // background: #8c589e;
      }
    }
  }

  @media (max-width: 768px) {
    ul li {
      font-size: 14px !important;
    }
    ul li a {
      padding: 2px 0;
    }
    grid-template-columns: 1fr;
    row-gap: 10px;
    width: 100%;
    margin-top: 10px;

    ul {
      width: 100%;
      justify-content: center;
    }
  }
}
.admin {
  .table-pagination ul li {
    color: #305052;
    border-color: #305052 !important;
  }
}
.dark {
  ul {
    li a {
      color: #fff !important;
    }
  }
}
.table-pagination__text {
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
  @media (max-width: 991px) {
    text-align: center;
    flex-direction: column-reverse;
  }
}
