// .shadow-inset {
//   box-shadow: 0 0 4px 1px rgba(0,0,0,0.1) inset;
//   padding: 25px 24px;
//   border-radius: 12px;
//   min-width: 350px;
//   .filters {
//     box-shadow: none;
//     padding: 0;

//     .filters__items {
//       display: flex;
//       justify-content: space-between;
//       @media (max-width: 389px) {
//         // flex-wrap: wrap;
//         // &>div {
//         //   width: 250px;
//         // }
//       }
//     }
//   }
//   @media (max-width: 991px) {
//     padding: 10px;
//     min-width: unset;
//     .filters__items {
//       & > div {
//         width: calc(50% - 12px);
//       }
//     }
//   }
// }

// .stats {
//   display: flex;
//   column-gap: 30px;

//   @media (max-width: 991px) {
//     margin-top: 20px;
//   }
//   .shadow-inset {
//     @media (max-width: 1200px) {
//       width: 100%;
//     }
//   }
//   .filters {
//     width: 100%;
//   }
//   h6 {
//     font-size: 20px;

//     @media (max-width: 768px) {
//       font-size: 1rem;
//     }
//   }

//   .stats-item {
//     position: relative;
//     font-size: 16px;
//     margin-bottom: 5px;
//     display: flex;
//     justify-content: space-between;

//     &:before {
//       content: "";
//       width: 100%;
//       height: 2px;
//       border-bottom: 2px dotted rgba(0, 0, 0, 0.5);
//       position: absolute;
//       left: 0;
//       bottom: 8px;
//     }
//     span,
//     b {
//       background: #fff;
//       position: relative;
//     }
//     span {
//       padding-right: 5px;
//     }
//     b {
//       padding-left: 5px;
//     }

//     @media (max-width: 768px) {
//       font-size: 14px;
//     }
//   }
// }
// .dark {
//   .stats {
//     .stats-item span, .stats-item b {
//       background: #305052;
//       color: #fff;
//     }
//     .stats-item {
//       &:before {
//         border-color: #fff;
//       }
//     }
//   }
// }

// .stats-tables {
//   h5 {
//     margin-bottom: 15px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;

//     @media (max-width: 768px) {
//       flex-direction: column;
//       align-items: flex-start;
//     }
//   }
// }
// .stats-tables__placeholder {
//   font-size: 16px;
//   margin-bottom: 5px;

//   + select {
//     width: 100%;
//     min-width: 200px;
//   }
// }

// .stats-tables__select {
//   display: flex;
//   flex-direction: column;

//   @media (max-width: 768px) {
//     width: 100%;
//   }
// }
// // .stats-tables {
// .orders-table {
//   position: relative;
// }
// .with-preloader {
//   display: flex;
//   justify-content: center;
//   // align-items: center;
//   padding-top: 100px;
//   background: #E7EBF2D1;
//   position: absolute;
//   left: 0;
//   top: 0;
//   border-radius: 12px;
//   width: 100%;
//   height: 100%;
//   z-index: 5;
// }
// // }
// .dark {
//   .stats-tables {
//     .with-preloader {
//       background: rgba(0, 0, 0, 0.5);
//     }
//   }
// }

// .user-stats {
//   .rdt_TableRow {
//     cursor: pointer;
//     &:hover {
//       background: rgba(0,0,0,.1) !important;
//     }
//   }
// }

.admin-panel__item {
  background-color: #e7ebf2;
  padding: 21px 16px;
  position: relative;
  border-radius: 10px;
}
.stats {
  .stats-list {
    display: flex;
    align-items: center;
    column-gap: 45px;
    flex-wrap: wrap;
  }
  .stats-item {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #051229;
  }
  &_orders {
    .stats-list {
      display: grid;
      column-gap: 17px;
      row-gap: 6px;
      margin-top: -18px;
      grid-template-columns: 1fr 1fr 1fr;
      padding-top: 20px;
      border-top: 1px dashed #5d6981;
      @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
      }
    }
    .stats-item {
      padding: 10px 12px 11px 14px;
      border-radius: 10px;
      background: #f4f6fa;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &_without_line {
    .stats-list {
      border-top: none;
    }
    
  }
}
.admin-panel__item h6 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  letter-spacing: -0.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #051229;
  margin-bottom: 21px;
}

.go-to-settings {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  line-height: 17.07px;
  letter-spacing: -0.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  column-gap: 16px;
}
.mobile-list__item {
  .mobile-list__footer_centered {
    .mobile-list__line, .mobile-list__value {
      width: 100%;
    }
    .mobile-list__key {
      display: none;
    }
  }
}
