.bg {
  transform: scale(0.8);
  width: 35px;
  height: 35px;
  background: url("../../images/no-photos.png") no-repeat;
}

.bg-aa {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -122px -120px;
}
.bg-aaa {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -180px -10px;
}
.bg-aag {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -180px -65px;
}
.bg-aap {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -180px -120px;
}
.bg-aaq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -175px;
}
.bg-aar {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -175px;
}
.bg-aas {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -175px;
}
.bg-aat {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -175px;
}
.bg-aau {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -235px -10px;
}
.bg-aav {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -235px -65px;
}
.bg-aaw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -235px -120px;
}
.bg-aax {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -235px -175px;
}
.bg-aay {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -230px;
}
.bg-aaz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -230px;
}
.bg-ab {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -230px;
}
.bg-aba {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -230px;
}
.bg-abb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -230px;
}
.bg-abc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -290px -10px;
}
.bg-abd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -290px -65px;
}
.bg-abe {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -290px -120px;
}
.bg-abf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -290px -175px;
}
.bg-abh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -290px -230px;
}
.bg-abi {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -285px;
}
.bg-abg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -285px;
}
.bg-abk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -285px;
}
.bg-abj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -285px;
}
.bg-abm {
  width: 35px;
  height: 32px;
  background: url("../../images/css_sprites.png") -725px -1660px;
}
.bg-abn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -285px;
}
.bg-abo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -285px;
}
.bg-abp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -345px -10px;
}
.bg-abl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -345px -65px;
}
.bg-abq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -345px -120px;
}
.bg-abr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -345px -175px;
}
.bg-abt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -345px -230px;
}
.bg-abs {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -345px -285px;
}
.bg-abv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -340px;
}
.bg-abx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -340px;
}
.bg-aby {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -340px;
}
.bg-abw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -340px;
}
.bg-ac {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -340px;
}
.bg-aca {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -340px;
}
.bg-acb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -340px;
}
.bg-acd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -400px -10px;
}
.bg-acc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -400px -65px;
}
.bg-ace {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -400px -120px;
}
.bg-acf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -400px -175px;
}
.bg-acg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -400px -230px;
}
.bg-ach {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -400px -285px;
}
.bg-acj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -400px -340px;
}
.bg-ack {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -395px;
}
.bg-acl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -395px;
}
.bg-acm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -395px;
}
.bg-aco {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -395px;
}
.bg-acn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -395px;
}
.bg-acp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -395px;
}
.bg-acr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -395px;
}
.bg-acs {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -395px;
}
.bg-acu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -455px -10px;
}
.bg-acw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -455px -65px;
}
.bg-acy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -455px -120px;
}
.bg-ad {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -455px -175px;
}
.bg-adb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -455px -230px;
}
.bg-adf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -455px -285px;
}
.bg-adg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -455px -340px;
}
.bg-adh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -455px -395px;
}
.bg-adj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -450px;
}
.bg-adi {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -450px;
}
.bg-adl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -450px;
}
.bg-adm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -450px;
}
.bg-adn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -450px;
}
.bg-ado {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -450px;
}
.bg-ads {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -450px;
}
.bg-adp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -450px;
}
.bg-adq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -450px;
}
.bg-adt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -510px -10px;
}
.bg-adv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -510px -65px;
}
.bg-acq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -510px -120px;
}
.bg-ada {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -510px -175px;
}
.bg-adc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -510px -230px;
}
.bg-ady {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -510px -285px;
}
.bg-aee {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -510px -340px;
}
.bg-adk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -510px -395px;
}
.bg-adr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -510px -450px;
}
.bg-adx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -505px;
}
.bg-adz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -505px;
}
.bg-ae {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -505px;
}
.bg-aeg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -505px;
}
.bg-aef {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -505px;
}
.bg-aed {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -505px;
}
.bg-aei {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -505px;
}
.bg-aeb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -505px;
}
.bg-aek {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -505px;
}
.bg-aeh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -505px;
}
.bg-acv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -565px -10px;
}
.bg-adu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -565px -65px;
}
.bg-acz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -565px -120px;
}
.bg-adw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -565px -175px;
}
.bg-aea {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -565px -230px;
}
.bg-aec {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -565px -285px;
}
.bg-aej {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -565px -340px;
}
.bg-ael {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -565px -395px;
}
.bg-aem {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -565px -450px;
}
.bg-aen {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -565px -505px;
}
.bg-aeo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -560px;
}
.bg-aep {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -560px;
}
.bg-aeq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -560px;
}
.bg-aer {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -560px;
}
.bg-aes {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -560px;
}
.bg-aet {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -560px;
}
.bg-aeu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -560px;
}
.bg-aev {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -560px;
}
.bg-aew {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -560px;
}
.bg-aex {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -560px;
}
.bg-aey {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -560px;
}
.bg-aez {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -620px -10px;
}
.bg-afa {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -620px -65px;
}
.bg-afb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -620px -120px;
}
.bg-afc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -620px -175px;
}
.bg-afd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -620px -230px;
}
.bg-afe {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -620px -285px;
}
.bg-afg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -620px -340px;
}
.bg-aff {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -620px -395px;
}
.bg-afh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -620px -450px;
}
.bg-afi {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -620px -505px;
}
.bg-afl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -620px -560px;
}
.bg-afn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -615px;
}
.bg-afo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -615px;
}
.bg-afp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -615px;
}
.bg-afq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -615px;
}
.bg-afr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -615px;
}
.bg-afs {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -615px;
}
.bg-aft {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -615px;
}
.bg-afu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -615px;
}
.bg-afv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -615px;
}
.bg-afw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -615px;
}
.bg-afx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -615px;
}
.bg-afy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -615px;
}
.bg-afz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -675px -10px;
}
.bg-aga {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -675px -65px;
}
.bg-agb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -675px -120px;
}
.bg-agc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -675px -175px;
}
.bg-agd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -675px -230px;
}
.bg-age {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -675px -285px;
}
.bg-agf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -675px -340px;
}
.bg-agg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -675px -395px;
}
.bg-agh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -675px -450px;
}
.bg-agi {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -675px -505px;
}
.bg-agj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -675px -560px;
}
.bg-agk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -675px -615px;
}
.bg-agl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -670px;
}
.bg-agm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -670px;
}
.bg-agn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -670px;
}
.bg-ago {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -670px;
}
.bg-agp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -670px;
}
.bg-agq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -670px;
}
.bg-agr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -670px;
}
.bg-ags {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -670px;
}
.bg-agt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -670px;
}
.bg-agu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -670px;
}
.bg-agv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -670px;
}
.bg-agw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -670px;
}
.bg-agx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -670px;
}
.bg-agy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -730px -10px;
}
.bg-ah {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -730px -65px;
}
.bg-agz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -730px -120px;
}
.bg-aha {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -730px -175px;
}
.bg-ahb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -730px -230px;
}
.bg-ahc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -730px -285px;
}
.bg-ahd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -730px -340px;
}
.bg-ahe {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -730px -395px;
}
.bg-ahf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -730px -450px;
}
.bg-ahg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -730px -505px;
}
.bg-ahh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -730px -560px;
}
.bg-ahi {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -730px -615px;
}
.bg-ahj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -730px -670px;
}
.bg-ahk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -725px;
}
.bg-ahl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -725px;
}
.bg-ahm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -725px;
}
.bg-ahn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -725px;
}
.bg-aho {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -725px;
}
.bg-ahp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -725px;
}
.bg-ahq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -725px;
}
.bg-ahr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -725px;
}
.bg-ahs {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -725px;
}
.bg-aht {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -725px;
}
.bg-ahv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -725px;
}
.bg-ahw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -725px;
}
.bg-ahx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -725px;
}
.bg-ahy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -725px -725px;
}
.bg-ahz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -785px -10px;
}
.bg-ai {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -785px -65px;
}
.bg-aic {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -785px -120px;
}
.bg-aid {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -785px -175px;
}
.bg-aie {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -785px -230px;
}
.bg-aif {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -785px -285px;
}
.bg-aig {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -785px -340px;
}
.bg-aih {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -785px -395px;
}
.bg-aii {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -785px -450px;
}
.bg-aij {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -785px -505px;
}
.bg-aik {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -785px -560px;
}
.bg-ail {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -785px -615px;
}
.bg-ain {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -785px -670px;
}
.bg-aio {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -785px -725px;
}
.bg-aip {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -780px;
}
.bg-aiq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -780px;
}
.bg-air {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -780px;
}
.bg-ais {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -780px;
}
.bg-ait {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -780px;
}
.bg-aiu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -780px;
}
.bg-aiv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -780px;
}
.bg-aiw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -780px;
}
.bg-aix {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -780px;
}
.bg-aiy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -780px;
}
.bg-aiz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -780px;
}
.bg-aj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -780px;
}
.bg-aja {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -780px;
}
.bg-ajb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -725px -780px;
}
.bg-ajc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -780px -780px;
}
.bg-ajd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -840px -10px;
}
.bg-aje {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -840px -65px;
}
.bg-ajf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -840px -120px;
}
.bg-ajg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -840px -175px;
}
.bg-ajh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -840px -230px;
}
.bg-aji {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -840px -285px;
}
.bg-ajj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -840px -340px;
}
.bg-ajk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -840px -395px;
}
.bg-ajl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -840px -450px;
}
.bg-ajm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -840px -505px;
}
.bg-ajn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -840px -560px;
}
.bg-ajo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -840px -615px;
}
.bg-ajp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -840px -670px;
}
.bg-ajq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -840px -725px;
}
.bg-ajr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -840px -780px;
}
.bg-ajs {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -835px;
}
.bg-ajt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -835px;
}
.bg-aju {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -835px;
}
.bg-ajv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -835px;
}
.bg-ajw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -835px;
}
.bg-ajx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -835px;
}
.bg-ajy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -835px;
}
.bg-ajz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -835px;
}
.bg-ak {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -835px;
}
.bg-aka {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -835px;
}
.bg-akb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -835px;
}
.bg-akc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -835px;
}
.bg-akd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -835px;
}
.bg-ake {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -725px -835px;
}
.bg-akf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -780px -835px;
}
.bg-akg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -835px -835px;
}
.bg-akh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -895px -10px;
}
.bg-aki {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -895px -65px;
}
.bg-akj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -895px -120px;
}
.bg-akk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -895px -175px;
}
.bg-akl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -895px -230px;
}
.bg-akm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -895px -285px;
}
.bg-akn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -895px -340px;
}
.bg-ako {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -895px -395px;
}
.bg-akp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -895px -450px;
}
.bg-akq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -895px -505px;
}
.bg-akr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -895px -560px;
}
.bg-aks {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -895px -615px;
}
.bg-akt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -895px -670px;
}
.bg-akw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -895px -725px;
}
.bg-akx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -895px -780px;
}
.bg-aky {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -895px -835px;
}
.bg-akz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -890px;
}
.bg-ala {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -890px;
}
.bg-alb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -890px;
}
.bg-alc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -890px;
}
.bg-ald {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -890px;
}
.bg-ale {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -890px;
}
.bg-alf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -890px;
}
.bg-alg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -890px;
}
.bg-alh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -890px;
}
.bg-ali {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -890px;
}
.bg-alj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -890px;
}
.bg-alm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -890px;
}
.bg-aln {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -890px;
}
.bg-alo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -725px -890px;
}
.bg-alp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -780px -890px;
}
.bg-alq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -835px -890px;
}
.bg-alr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -890px -890px;
}
.bg-als {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -950px -10px;
}
.bg-alt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -950px -65px;
}
.bg-alu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -950px -120px;
}
.bg-alv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -950px -175px;
}
.bg-alw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -950px -230px;
}
.bg-alx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -950px -285px;
}
.bg-aly {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -950px -340px;
}
.bg-alz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -950px -395px;
}
.bg-am {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -950px -450px;
}
.bg-ama {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -950px -505px;
}
.bg-amb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -950px -560px;
}
.bg-amc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -950px -615px;
}
.bg-amd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -950px -670px;
}
.bg-ame {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -950px -725px;
}
.bg-amf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -950px -780px;
}
.bg-amg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -950px -835px;
}
.bg-amh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -950px -890px;
}
.bg-ami {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -945px;
}
.bg-amk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -945px;
}
.bg-amj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -945px;
}
.bg-aml {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -945px;
}
.bg-amm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -945px;
}
.bg-amn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -945px;
}
.bg-amo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -945px;
}
.bg-amp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -945px;
}
.bg-amq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -945px;
}
.bg-amr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -945px;
}
.bg-ams {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -945px;
}
.bg-amt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -945px;
}
.bg-amv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -945px;
}
.bg-amw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -725px -945px;
}
.bg-amx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -780px -945px;
}
.bg-amy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -835px -945px;
}
.bg-amz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -890px -945px;
}
.bg-an {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -945px -945px;
}
.bg-ana {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1005px -10px;
}
.bg-anc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1005px -65px;
}
.bg-anb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1005px -120px;
}
.bg-ane {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1005px -175px;
}
.bg-anf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1005px -230px;
}
.bg-ang {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1005px -285px;
}
.bg-anh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1005px -340px;
}
.bg-ani {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1005px -395px;
}
.bg-anj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1005px -450px;
}
.bg-ank {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1005px -505px;
}
.bg-anm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1005px -560px;
}
.bg-anl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1005px -615px;
}
.bg-ann {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1005px -670px;
}
.bg-ano {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1005px -725px;
}
.bg-anq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1005px -780px;
}
.bg-ant {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1005px -835px;
}
.bg-anv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1005px -890px;
}
.bg-anw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1005px -945px;
}
.bg-anx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -1000px;
}
.bg-any {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -1000px;
}
.bg-ao {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -1000px;
}
.bg-aq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -1000px;
}
.bg-ar {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -1000px;
}
.bg-at {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -1000px;
}
.bg-au {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -1000px;
}
.bg-av {
  width: 37px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -10px;
}
.bg-ax {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -1000px;
}
.bg-aw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -1000px;
}
.bg-ay {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -1000px;
}
.bg-ba {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -1000px;
}
.bg-bb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -1000px;
}
.bg-bc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -1000px;
}
.bg-bd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -725px -1000px;
}
.bg-be {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -780px -1000px;
}
.bg-bf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -835px -1000px;
}
.bg-bg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -890px -1000px;
}
.bg-bh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -945px -1000px;
}
.bg-bi {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1000px -1000px;
}
.bg-bj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -10px;
}
.bg-bk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -65px;
}
.bg-bl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -120px;
}
.bg-bm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -175px;
}
.bg-bn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -230px;
}
.bg-bo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -285px;
}
.bg-bp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -340px;
}
.bg-bq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -395px;
}
.bg-br {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -450px;
}
.bg-bs {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -505px;
}
.bg-bt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -560px;
}
.bg-bu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -615px;
}
.bg-bv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -670px;
}
.bg-bw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -725px;
}
.bg-bx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -780px;
}
.bg-bz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -835px;
}
.bg-ca {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -890px;
}
.bg-cb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -945px;
}
.bg-cc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1060px -1000px;
}
.bg-cd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -1055px;
}
.bg-ce {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -1055px;
}
.bg-cf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -1055px;
}
.bg-cg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -1055px;
}
.bg-ch {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -1055px;
}
.bg-ci {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -1055px;
}
.bg-cj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -1055px;
}
.bg-ck {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -1055px;
}
.bg-cl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -1055px;
}
.bg-cm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -1055px;
}
.bg-cn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -1055px;
}
.bg-co {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -1055px;
}
.bg-cp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -1055px;
}
.bg-cq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -725px -1055px;
}
.bg-cr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -780px -1055px;
}
.bg-cs {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -835px -1055px;
}
.bg-ct {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -890px -1055px;
}
.bg-cu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -945px -1055px;
}
.bg-cv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1000px -1055px;
}
.bg-cw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1055px -1055px;
}
.bg-cx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -10px;
}
.bg-cy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -65px;
}
.bg-cz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -120px;
}
.bg-da {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -175px;
}
.bg-db {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -230px;
}
.bg-dc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -285px;
}
.bg-de {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -340px;
}
.bg-dd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -395px;
}
.bg-df {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -450px;
}
.bg-dg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -505px;
}
.bg-dh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -560px;
}
.bg-di {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -615px;
}
.bg-dj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -670px;
}
.bg-dk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -725px;
}
.bg-dl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -780px;
}
.bg-dm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -835px;
}
.bg-dn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -890px;
}
.bg-do {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -945px;
}
.bg-dp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -1000px;
}
.bg-dq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1115px -1055px;
}
.bg-dr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -1110px;
}
.bg-ds {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -1110px;
}
.bg-dt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -1110px;
}
.bg-du {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -1110px;
}
.bg-dv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -1110px;
}
.bg-dw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -1110px;
}
.bg-dx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -1110px;
}
.bg-dz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -1110px;
}
.bg-dy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -1110px;
}
.bg-ea {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -1110px;
}
.bg-eb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -1110px;
}
.bg-ec {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -1110px;
}
.bg-ed {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -1110px;
}
.bg-ee {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -725px -1110px;
}
.bg-ef {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -780px -1110px;
}
.bg-eg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -835px -1110px;
}
.bg-eh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -890px -1110px;
}
.bg-ei {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -945px -1110px;
}
.bg-ej {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1000px -1110px;
}
.bg-el {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1055px -1110px;
}
.bg-em {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1110px -1110px;
}
.bg-en {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -10px;
}
.bg-eo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -65px;
}
.bg-ep {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -120px;
}
.bg-eq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -175px;
}
.bg-es {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -230px;
}
.bg-et {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -285px;
}
.bg-eu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -340px;
}
.bg-ev {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -395px;
}
.bg-ew {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -450px;
}
.bg-ex {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -505px;
}
.bg-ey {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -560px;
}
.bg-ez {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -615px;
}
.bg-fa {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -670px;
}
.bg-fb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -725px;
}
.bg-fc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -780px;
}
.bg-fd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -835px;
}
.bg-fe {
  width: 36px;
  height: 35px;
  background: url("../../images/css_sprites.png") -124px -10px;
}
.bg-ff {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -890px;
}
.bg-fg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -945px;
}
.bg-fh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -1000px;
}
.bg-fi {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -1055px;
}
.bg-fj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1170px -1110px;
}
.bg-fk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -1165px;
}
.bg-fl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -1165px;
}
.bg-fm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -1165px;
}
.bg-fo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -1165px;
}
.bg-fr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -1165px;
}
.bg-fs {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -1165px;
}
.bg-ft {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -1165px;
}
.bg-fu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -1165px;
}
.bg-full {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -1165px;
}
.bg-fv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -1165px;
}
.bg-fw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -1165px;
}
.bg-fx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -1165px;
}
.bg-fy {
  width: 36px;
  height: 35px;
  background: url("../../images/css_sprites.png") -124px -65px;
}
.bg-fz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -1165px;
}
.bg-ga {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -725px -1165px;
}
.bg-gb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -780px -1165px;
}
.bg-gd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -835px -1165px;
}
.bg-ge {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -890px -1165px;
}
.bg-gf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -945px -1165px;
}
.bg-gg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1000px -1165px;
}
.bg-gh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1055px -1165px;
}
.bg-gi {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1110px -1165px;
}
.bg-gj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1165px -1165px;
}
.bg-gk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -10px;
}
.bg-gl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -65px;
}
.bg-gm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -120px;
}
.bg-go {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -175px;
}
.bg-gp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -230px;
}
.bg-gq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -285px;
}
.bg-gr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -340px;
}
.bg-gs {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -395px;
}
.bg-gt {
  width: 35px;
  height: 18px;
  background: url("../../images/css_sprites.png") -835px -1660px;
}
.bg-gu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -450px;
}
.bg-gv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -505px;
}
.bg-gw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -560px;
}
.bg-gx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -615px;
}
.bg-gz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -670px;
}
.bg-ha {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -725px;
}
.bg-hb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -780px;
}
.bg-hc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -835px;
}
.bg-he {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -890px;
}
.bg-hg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -945px;
}
.bg-hh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -1000px;
}
.bg-hi {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -1055px;
}
.bg-hj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -1110px;
}
.bg-hk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1225px -1165px;
}
.bg-hl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -1220px;
}
.bg-hm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -1220px;
}
.bg-hn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -1220px;
}
.bg-ho {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -1220px;
}
.bg-hq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -1220px;
}
.bg-hr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -1220px;
}
.bg-ht {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -1220px;
}
.bg-hu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -1220px;
}
.bg-hw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -1220px;
}
.bg-hx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -1220px;
}
.bg-hy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -1220px;
}
.bg-hz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -1220px;
}
.bg-ia {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -1220px;
}
.bg-ib {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -725px -1220px;
}
.bg-ie {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -780px -1220px;
}
.bg-ig {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -835px -1220px;
}
.bg-ih {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -890px -1220px;
}
.bg-ii {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -945px -1220px;
}
.bg-ij {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1000px -1220px;
}
.bg-ik {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1055px -1220px;
}
.bg-il {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1110px -1220px;
}
.bg-im {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1165px -1220px;
}
.bg-io {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1220px -1220px;
}
.bg-ip {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -10px;
}
.bg-iq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -65px;
}
.bg-ir {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -120px;
}
.bg-it {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -175px;
}
.bg-iu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -230px;
}
.bg-iv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -285px;
}
.bg-iw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -340px;
}
.bg-ix {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -395px;
}
.bg-iy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -450px;
}
.bg-iz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -505px;
}
.bg-ja {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -560px;
}
.bg-jb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -615px;
}
.bg-jc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -670px;
}
.bg-jd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -725px;
}
.bg-je {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -780px;
}
.bg-jf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -835px;
}
.bg-jg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -890px;
}
.bg-jh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -945px;
}
.bg-ji {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -1000px;
}
.bg-jj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -1055px;
}
.bg-jl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -1110px;
}
.bg-jm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -1165px;
}
.bg-jn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1280px -1220px;
}
.bg-jo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -1275px;
}
.bg-jp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -1275px;
}
.bg-jq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -1275px;
}
.bg-jr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -1275px;
}
.bg-js {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -1275px;
}
.bg-jt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -1275px;
}
.bg-ju {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -1275px;
}
.bg-jv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -1275px;
}
.bg-jw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -1275px;
}
.bg-jx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -1275px;
}
.bg-jy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -1275px;
}
.bg-jz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -1275px;
}
.bg-ka {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -1275px;
}
.bg-kb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -725px -1275px;
}
.bg-kc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -780px -1275px;
}
.bg-kd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -835px -1275px;
}
.bg-ke {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -890px -1275px;
}
.bg-kf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -945px -1275px;
}
.bg-kg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1000px -1275px;
}
.bg-kh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1055px -1275px;
}
.bg-ki {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1110px -1275px;
}
.bg-kj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1165px -1275px;
}
.bg-kk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1220px -1275px;
}
.bg-kl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1275px -1275px;
}
.bg-km {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -10px;
}
.bg-kn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -65px;
}
.bg-ko {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -120px;
}
.bg-kp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -175px;
}
.bg-kq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -230px;
}
.bg-kr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -285px;
}
.bg-ks {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -340px;
}
.bg-kt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -395px;
}
.bg-ku {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -450px;
}
.bg-kv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -505px;
}
.bg-kw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -560px;
}
.bg-kx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -615px;
}
.bg-ky {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -670px;
}
.bg-kz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -725px;
}
.bg-la {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -780px;
}
.bg-lb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -835px;
}
.bg-lc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -890px;
}
.bg-ld {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -945px;
}
.bg-le {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -1000px;
}
.bg-lf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -1055px;
}
.bg-lg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -1110px;
}
.bg-lh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -1165px;
}
.bg-li {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -1220px;
}
.bg-lj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1335px -1275px;
}
.bg-lk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -1330px;
}
.bg-ll {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -1330px;
}
.bg-lm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -1330px;
}
.bg-ln {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -1330px;
}
.bg-lo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -1330px;
}
.bg-lp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -1330px;
}
.bg-lq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -1330px;
}
.bg-lr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -1330px;
}
.bg-ls {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -1330px;
}
.bg-lt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -1330px;
}
.bg-lu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -1330px;
}
.bg-lv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -1330px;
}
.bg-lw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -1330px;
}
.bg-lx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -725px -1330px;
}
.bg-ly {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -780px -1330px;
}
.bg-lz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -835px -1330px;
}
.bg-ma {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -890px -1330px;
}
.bg-mb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -945px -1330px;
}
.bg-mc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1000px -1330px;
}
.bg-md {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1055px -1330px;
}
.bg-me {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1110px -1330px;
}
.bg-mf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1165px -1330px;
}
.bg-mg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1220px -1330px;
}
.bg-mi {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1275px -1330px;
}
.bg-mj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1330px -1330px;
}
.bg-mk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -10px;
}
.bg-ml {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -65px;
}
.bg-mm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -120px;
}
.bg-mn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -175px;
}
.bg-mo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -230px;
}
.bg-mp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -285px;
}
.bg-mq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -340px;
}
.bg-mr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -395px;
}
.bg-ms {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -450px;
}
.bg-mt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -505px;
}
.bg-mu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -560px;
}
.bg-mv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -615px;
}
.bg-mw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -670px;
}
.bg-mx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -725px;
}
.bg-my {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -780px;
}
.bg-mz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -835px;
}
.bg-nb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -890px;
}
.bg-nc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -945px;
}
.bg-ne {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -1000px;
}
.bg-nf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -1055px;
}
.bg-ng {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -1110px;
}
.bg-nh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -1165px;
}
.bg-ni {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -1220px;
}
.bg-nj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -1275px;
}
.bg-nk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1390px -1330px;
}
.bg-nl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -1385px;
}
.bg-nm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -1385px;
}
.bg-nn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -1385px;
}
.bg-no {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -1385px;
}
.bg-np {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -1385px;
}
.bg-nq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -1385px;
}
.bg-nr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -1385px;
}
.bg-ns {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -1385px;
}
.bg-nt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -1385px;
}
.bg-nu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -1385px;
}
.bg-nv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -1385px;
}
.bg-nw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -1385px;
}
.bg-ny {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -1385px;
}
.bg-nz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -725px -1385px;
}
.bg-ob {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -780px -1385px;
}
.bg-oc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -835px -1385px;
}
.bg-od {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -890px -1385px;
}
.bg-oe {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -945px -1385px;
}
.bg-of {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1000px -1385px;
}
.bg-og {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1055px -1385px;
}
.bg-oh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1110px -1385px;
}
.bg-oi {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1165px -1385px;
}
.bg-oj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1220px -1385px;
}
.bg-ok {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1275px -1385px;
}
.bg-ol {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1330px -1385px;
}
.bg-om {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1385px -1385px;
}
.bg-oo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -10px;
}
.bg-op {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -65px;
}
.bg-oq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -120px;
}
.bg-os {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -175px;
}
.bg-ot {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -230px;
}
.bg-ou {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -285px;
}
.bg-ov {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -340px;
}
.bg-ow {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -395px;
}
.bg-ox {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -450px;
}
.bg-oy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -505px;
}
.bg-oz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -560px;
}
.bg-pa {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -615px;
}
.bg-pb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -670px;
}
.bg-pc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -725px;
}
.bg-pd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -780px;
}
.bg-pf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -835px;
}
.bg-pg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -890px;
}
.bg-ph {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -945px;
}
.bg-pj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -1000px;
}
.bg-pl {
  width: 36px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -120px;
}
.bg-pm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -1055px;
}
.bg-pn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -1110px;
}
.bg-po {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -1165px;
}
.bg-pp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -1220px;
}
.bg-pq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -1275px;
}
.bg-pr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -1330px;
}
.bg-ps {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1445px -1385px;
}
.bg-pt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -1440px;
}
.bg-pu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -1440px;
}
.bg-pv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -1440px;
}
.bg-pw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -1440px;
}
.bg-px {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -1440px;
}
.bg-py {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -1440px;
}
.bg-pz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -1440px;
}
.bg-qa {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -1440px;
}
.bg-qb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -1440px;
}
.bg-qc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -1440px;
}
.bg-qd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -1440px;
}
.bg-qe {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -1440px;
}
.bg-qf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -1440px;
}
.bg-qg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -725px -1440px;
}
.bg-qh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -780px -1440px;
}
.bg-qi {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -835px -1440px;
}
.bg-qj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -890px -1440px;
}
.bg-qk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -945px -1440px;
}
.bg-ql {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1000px -1440px;
}
.bg-qm {
  width: 35px;
  height: 27px;
  background: url("../../images/css_sprites.png") -780px -1660px;
}
.bg-qn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1055px -1440px;
}
.bg-qo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1110px -1440px;
}
.bg-qp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1165px -1440px;
}
.bg-qq {
  width: 29px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1720px -10px;
}
.bg-qr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1220px -1440px;
}
.bg-qs {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1275px -1440px;
}
.bg-qt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1330px -1440px;
}
.bg-qu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1385px -1440px;
}
.bg-qv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1440px -1440px;
}
.bg-qx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -10px;
}
.bg-qy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -65px;
}
.bg-qz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -120px;
}
.bg-ra {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -175px;
}
.bg-rb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -230px;
}
.bg-rc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -285px;
}
.bg-rd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -340px;
}
.bg-re {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -395px;
}
.bg-rf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -450px;
}
.bg-rg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -505px;
}
.bg-rh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -560px;
}
.bg-ri {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -615px;
}
.bg-rj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -670px;
}
.bg-rk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -725px;
}
.bg-rl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -780px;
}
.bg-rm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -835px;
}
.bg-rn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -890px;
}
.bg-ro {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -945px;
}
.bg-rp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -1000px;
}
.bg-rq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -1055px;
}
.bg-rr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -1110px;
}
.bg-rs {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -1165px;
}
.bg-rt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -1220px;
}
.bg-ru {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -1275px;
}
.bg-rv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -1330px;
}
.bg-rx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -1385px;
}
.bg-ry {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1500px -1440px;
}
.bg-rz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -1495px;
}
.bg-sa {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -1495px;
}
.bg-sb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -1495px;
}
.bg-sc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -1495px;
}
.bg-sd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -1495px;
}
.bg-sf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -1495px;
}
.bg-sg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -1495px;
}
.bg-sh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -1495px;
}
.bg-si {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -1495px;
}
.bg-sj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -1495px;
}
.bg-sk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -1495px;
}
.bg-sl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -1495px;
}
.bg-sm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -1495px;
}
.bg-sn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -725px -1495px;
}
.bg-so {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -780px -1495px;
}
.bg-sp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -835px -1495px;
}
.bg-sq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -890px -1495px;
}
.bg-sr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -945px -1495px;
}
.bg-ss {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1000px -1495px;
}
.bg-st {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1055px -1495px;
}
.bg-su {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1110px -1495px;
}
.bg-sv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1165px -1495px;
}
.bg-sw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1220px -1495px;
}
.bg-sy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1275px -1495px;
}
.bg-sz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1330px -1495px;
}
.bg-ta {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1385px -1495px;
}
.bg-tc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1440px -1495px;
}
.bg-td {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1495px -1495px;
}
.bg-tf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -10px;
}
.bg-tg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -65px;
}
.bg-th {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -120px;
}
.bg-ti {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -175px;
}
.bg-tj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -230px;
}
.bg-tk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -285px;
}
.bg-tl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -340px;
}
.bg-tm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -395px;
}
.bg-tn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -450px;
}
.bg-tp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -505px;
}
.bg-tq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -560px;
}
.bg-tr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -615px;
}
.bg-ts {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -670px;
}
.bg-tu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -725px;
}
.bg-tw {
  width: 37px;
  height: 35px;
  background: url("../../images/css_sprites.png") -67px -10px;
}
.bg-tx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -780px;
}
.bg-tz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -835px;
}
.bg-ty {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -890px;
}
.bg-ua {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -945px;
}
.bg-ub {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -1000px;
}
.bg-uc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -1055px;
}
.bg-ud {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -1110px;
}
.bg-ue {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -1165px;
}
.bg-uf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -1220px;
}
.bg-ug {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -1275px;
}
.bg-uh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -1330px;
}
.bg-ui {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -1385px;
}
.bg-uj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -1440px;
}
.bg-uk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1555px -1495px;
}
.bg-ul {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -1550px;
}
.bg-um {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -1550px;
}
.bg-un {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -1550px;
}
.bg-uo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -1550px;
}
.bg-up {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -1550px;
}
.bg-uq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -1550px;
}
.bg-ur {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -1550px;
}
.bg-us {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -1550px;
}
.bg-ut {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -1550px;
}
.bg-uu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -1550px;
}
.bg-uv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -1550px;
}
.bg-uw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -1550px;
}
.bg-ux {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -1550px;
}
.bg-uy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -725px -1550px;
}
.bg-uz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -780px -1550px;
}
.bg-va {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -835px -1550px;
}
.bg-vb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -890px -1550px;
}
.bg-vc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -945px -1550px;
}
.bg-vd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1000px -1550px;
}
.bg-ve {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1055px -1550px;
}
.bg-vf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1110px -1550px;
}
.bg-vg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1165px -1550px;
}
.bg-vh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1220px -1550px;
}
.bg-vi {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1275px -1550px;
}
.bg-vj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1330px -1550px;
}
.bg-vk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1385px -1550px;
}
.bg-vl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1440px -1550px;
}
.bg-vm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1495px -1550px;
}
.bg-vn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1550px -1550px;
}
.bg-vo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -10px;
}
.bg-vp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -65px;
}
.bg-vq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -120px;
}
.bg-vs {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -175px;
}
.bg-vv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -230px;
}
.bg-vw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -285px;
}
.bg-vx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -340px;
}
.bg-vy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -395px;
}
.bg-vz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -450px;
}
.bg-wa {
  width: 37px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -65px;
}
.bg-wb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -505px;
}
.bg-wc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -560px;
}
.bg-wd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -615px;
}
.bg-we {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -670px;
}
.bg-wf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -725px;
}
.bg-wg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -780px;
}
.bg-wh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -835px;
}
.bg-wj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -890px;
}
.bg-wk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -945px;
}
.bg-wn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -1000px;
}
.bg-wo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -1055px;
}
.bg-wp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -1110px;
}
.bg-wr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -1165px;
}
.bg-wt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -1220px;
}
.bg-wu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -1275px;
}
.bg-wv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -1330px;
}
.bg-ww {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -1385px;
}
.bg-wx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -1440px;
}
.bg-wy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -1495px;
}
.bg-wz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1610px -1550px;
}
.bg-xa {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -1605px;
}
.bg-xb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -1605px;
}
.bg-xc {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -1605px;
}
.bg-xd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -1605px;
}
.bg-xe {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -1605px;
}
.bg-xf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -1605px;
}
.bg-xg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -1605px;
}
.bg-xh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -1605px;
}
.bg-xi {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -1605px;
}
.bg-xj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -1605px;
}
.bg-xk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -1605px;
}
.bg-xl {
  width: 37px;
  height: 35px;
  background: url("../../images/css_sprites.png") -67px -65px;
}
.bg-xm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -1605px;
}
.bg-xn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -1605px;
}
.bg-xo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -725px -1605px;
}
.bg-xp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -780px -1605px;
}
.bg-xq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -835px -1605px;
}
.bg-xr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -890px -1605px;
}
.bg-xs {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -945px -1605px;
}
.bg-xu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1000px -1605px;
}
.bg-xt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1055px -1605px;
}
.bg-xv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1110px -1605px;
}
.bg-xw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1165px -1605px;
}
.bg-xx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1220px -1605px;
}
.bg-xy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1275px -1605px;
}
.bg-xz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1330px -1605px;
}
.bg-ya {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1385px -1605px;
}
.bg-yb {
  width: 36px;
  height: 35px;
  background: url("../../images/css_sprites.png") -66px -120px;
}
.bg-yd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1440px -1605px;
}
.bg-yf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1495px -1605px;
}
.bg-ye {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1550px -1605px;
}
.bg-yg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1605px -1605px;
}
.bg-yh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -10px;
}
.bg-yi {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -65px;
}
.bg-yj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -120px;
}
.bg-yk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -175px;
}
.bg-yl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -230px;
}
.bg-ym {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -285px;
}
.bg-yn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -340px;
}
.bg-yo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -395px;
}
.bg-yp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -450px;
}
.bg-yq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -505px;
}
.bg-yr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -560px;
}
.bg-yu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -615px;
}
.bg-yv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -670px;
}
.bg-ys {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -725px;
}
.bg-yw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -780px;
}
.bg-yx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -835px;
}
.bg-yy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -890px;
}
.bg-yz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -945px;
}
.bg-za {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -1000px;
}
.bg-zb {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -1055px;
}
.bg-zd {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -1110px;
}
.bg-ze {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -1165px;
}
.bg-zf {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -1220px;
}
.bg-zg {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -1275px;
}
.bg-zh {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -1330px;
}
.bg-zi {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -1385px;
}
.bg-zj {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -1440px;
}
.bg-zk {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -1495px;
}
.bg-zl {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -1550px;
}
.bg-zm {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -1665px -1605px;
}
.bg-zn {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -10px -1660px;
}
.bg-zo {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -65px -1660px;
}
.bg-zp {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -120px -1660px;
}
.bg-zq {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -175px -1660px;
}
.bg-zr {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -230px -1660px;
}
.bg-zs {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -285px -1660px;
}
.bg-zt {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -340px -1660px;
}
.bg-zu {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -395px -1660px;
}
.bg-zv {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -450px -1660px;
}
.bg-zw {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -505px -1660px;
}
.bg-zx {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -560px -1660px;
}
.bg-zy {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -615px -1660px;
}
.bg-zz {
  width: 35px;
  height: 35px;
  background: url("../../images/css_sprites.png") -670px -1660px;
}
