.admin-settings {
  padding: 20px;
  label {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 16px;
  }
  @media (max-width: 768px) {
    padding: 10px 5px 5px 5px;
  }
  .settings__commission {
    display: grid;
    grid-template-columns: 300px;
    row-gap: 10px;
    button {
      margin-top: 10px;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .settings__commission {
    position: relative;

    .input-group-text {
      width: 38px;
    }
  }
}
.settings__commission {
  display: flex;
  justify-content: flex-start;
  column-gap: 14px;
  flex-wrap: wrap;
  row-gap: 14px;
  // align-items: ;
  .settings__commission-item {
    max-width: 230px;
    width: 100%;
  }
}
.toggler {
  display: flex;
  flex-direction: column-reverse;
  span {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #051229;
    margin-bottom: 15px;
  }
  .react-toggle-track {
    width: 83px;
    height: 31px;
    border-radius: 10px;
    background-color: #f4f6fa;
  }
  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #f4f6fa;
  }

  .react-toggle-thumb {
    width: 45px;
    height: 31px;
    border-radius: 10px;
    border: none;
    margin-top: -1px;
    box-shadow: none !important;
    outline: none !important;

    background: #fa3e3e;
  }
  .react-toggle.react-toggle--checked {
    .react-toggle-thumb {
      left: 38px;
      background-color: #50cb25;
    }
  }
  .react-toggle-track-check {
    z-index: 2;
    left: 56px;
  }
  .react-toggle-track-x {
    left: 18px;
    z-index: 2;
  }
}

.admin-panel__item {

  @media (max-width: 768px) {
    margin-left: -15px;
    margin-right: -15px;
  }
  .form-label {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #051229;
  }
  input.form-control {
    background: #f4f6fa;
    border: none;
    box-shadow: none;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10.5px 15px 10.5px 10px;
    color: #5d6981;

    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  input:-internal-autofill-selected {
    background-color: #f4f6fa !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }

  .input-group-text {
    border: none;
    box-shadow: none;
    background: #f4f6fa !important;
    padding-right: 5px;
    padding-left: 14px;
    border-radius: 10px;
  }

  input:auto-fill {
  }

  input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: -internal-light-dark(
      rgb(232, 240, 254),
      rgba(70, 90, 126, 0.4)
    ) !important;
    color: fieldtext !important;
  }
  .form-select {
    background-color: #f4f6fa !important;
    border: none;
    border-radius: 10px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 10px 14px 11px 14px;
  }
}

.settings__commission-button {
  background: #8161eb;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  cursor: pointer;
  height: 26px;
  // top: -10px;
  // right: 0;
}
