.admin-panel__content {
  background-color: #f4f6fa;
  padding: 21px 15px;
  border-radius: 10px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px; /* ширина scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: #f4f6fa; /* цвет дорожки */
  }
  &::-webkit-scrollbar-thumb {
    background-color: #8161eb; /* цвет плашки */
    border-radius: 20px; /* закругления плашки */
    border: 3px solid #8161eb; /* padding вокруг плашки */
  }
}
.admin-panel {
  h4 {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #051229;
  }
  h5 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #051229;
    margin-bottom: 24px;
  }
  .admin-panel__layout {
    display: grid;
    grid-template-columns: 330px 1fr;
    transition: 0.2s;
    column-gap: 30px;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    height: calc(100vh - 85px);
    .aside__show-menu {
      display: none;
    }
    @media (max-width: 768px) {
      .aside__show-menu {
        display: flex;
        background: #f4f6fa;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 13px;
        line-height: 15.85px;
        padding: 17px;
        border-radius: 10px;
        margin-top: 15px;
      }
      padding: 0;
      // display: flex;
      // flex-direction: column;
      grid-template-columns: 1fr;
      height: unset;
      .aside {
        padding: 30px 20px;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        min-height: unset;
        max-height: unset;

        .aside__content {
          width: 100%;
          max-width: unset;
        }
        .aside__inner {
          width: 100%;
        }
        .aside__user {
          margin-top: 0;
        }
        .aside__toggle {
          display: none;
        }
        .aside__menu {
          transition: 0.2s;
          max-height: 0px;
          overflow: hidden;
          &.show {
            max-height: 600px;
            overflow: visible;
          }
        }
      }
    }
  }
  .aside__inner {
    width: 300px;
  }
  .aside-logo {
    img {
      width: 159px;
      height: 31px;
    }
    transition: 0.2s;
    overflow: hidden;
  }
  &.expanded {
    .admin-panel__layout {
      grid-template-columns: 80px 1fr;

      @media (max-width: 768px) {
        grid-template-columns: 1fr !important;
      }
    }
    .aside {
      border-radius: 10px;
      overflow: hidden;
      @media (min-width: 768px) {
        max-width: 80px;
        .aside__content {
          max-width: 80px;
        }
      }
    }
    .aside__toggle {
      padding-left: 10px;
    }
  }
}

.aside {
  .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 5px 5px 0px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 17.63px;
    height: 28px;
    border-radius: 10px;
    letter-spacing: var(--Number2);
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    cursor: pointer;
    .flag {
      border-radius: 50%;
      margin-right: 5px;
      transform: scale(0.6);
    }
  }
  .dropdown-menu {
    padding: 4px;
    border: none;
    background-color: #e7ebf2;
    margin-top: 7px;
    overflow: hidden;
    border-radius: 10px;
    width: 90px;
    max-width: 90px;
    min-width: 90px;
    z-index: 10;
    box-shadow: 0 0 1px rgb(0, 0, 0, 0.1);
  }
  transition: 0.2s;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  max-width: 330px;
  max-height: calc(100vh - 110px);
  min-height: calc(100vh - 110px);
  overflow: hidden;
  transition: 0.2s;
  .aside__content {
    background: #8161eb;
    border-radius: 10px;
    padding: 15px;
    margin-top: 30px;
    max-width: 330px;
    transition: 0.2s;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .dropdown-toggle {
    width: 90px;
    margin-left: 60px;
    border-radius: 10px;
    border: none;
    background: #e7ebf2;
    color: #051229;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    letter-spacing: -0.5px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding-right: 10px;
    height: 30px;
    @media (max-width: 1200px) {
      margin-left: 10px;
    }
    &:after {
      display: none;
    }
    .flag {
      border-radius: 50%;
      margin-right: 10px;
      transform: scale(0.6);
      margin-left: -9px;
      margin-right: 5px;
      margin-top: -4px;
      & + span {
        margin-right: 10px;
      }
    }
    &:hover {
      color: #051229;
    }
    &:focus,&.active {
      outline: none;
      box-shadow: none !important;
    }
  }

  .aside-brand {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.2s;
    row-gap: 12px;
  }

  .aside__toggle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 11px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #fff;
    p {
      margin-bottom: 0;
      transition: 0.1s;
    }
  }
  .hamburger-lines {
    display: block;
    position: static;
    // top: 17px;
    // left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 3px;
    // background: #8161eb;
    border-radius: 5px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    border: 1px solid #fff;
  }

  .hamburger-lines .line {
    display: block;
    height: 2px;
    width: 16px;
    border-radius: 10px;
    background: #fff;
    transition: 0.3s;
  }

  .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }

  .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }
  .aside__user {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    margin-top: 15px;
    .services__auth-block {
      margin-top: 0;
      & > img {
        transition: 0.2s;
        width: 53px;
        height: 53px;
      }
      .service__auth-mail,
      .service__auth-balance {
        max-width: 185px;
        transition: 0.2s;
        overflow: hidden;
      }
    }
    .aside__return {
      background: #fbe0e5;
      border-radius: 10px;
      cursor: pointer;
      color: #fa3333;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 17.07px;
      letter-spacing: -0.5px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 33px;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 11px;
      margin-top: 17px;

      transition: 0.2s;
      img,
      span {
        transition: 0.2s;
      }
    }
  }
  .aside__menu {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    .aside__menu-children-items {
      padding: 0 15px;
      transition: 0.2s;
      height: 0;
      overflow: hidden;
    }
    .aside__menu-children {
      border-radius: 10px;

      &.active {
        .aside__menu-children-items {
          height: 113px;
          padding-bottom: 15px;
        }
      }
      &.colored {
        background: #9678fb;

        .aside__menu-item {
          &.active {
            background: #8161eb;
          }
        }
      }
    }
    .aside__menu-item {
      padding: 15px 13px;
      // height: 48px;
      color: #fff;
      display: grid;
      cursor: pointer;
      margin-bottom: 0;
      grid-template-columns: 26px 1fr;
      text-decoration: none;
      justify-content: flex-start;
      align-items: center;
      column-gap: 10px;
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 500;
      line-height: 18.29px;
      letter-spacing: -0.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      border-radius: 10px;
      &_return {
        margin-top: 20px;
        background: #7554df;
      }
      &.active {
        background: #9678fb;
      }
      .aside__menu-item-image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 18px;
      }
    }
  }
  &.expanded {
    .dropdown-toggle {
      justify-content: space-between;
      .flag+span {
        display: none;
      }
    }
    .dropdown-menu {
      max-width: 80px;
      width: 80px;
      min-width: 80px;
    }
    .aside__user {
      @media (min-width: 769px) {
        padding: 5px;
        max-width: 48px;
        overflow: hidden;
        .aside__return {
          padding: 9px;
          width: 38px;
          margin-top: 10px;
          span {
            display: none;
          }
        }
      }
      .services__auth-block {
        @media (min-width: 769px) {
          display: grid;
          grid-template-columns: "a-ava";
          grid-template-columns: 32px;
          margin-left: 3px;
          & > img {
            width: 32px;
            height: 32px;
          }
          .service__auth-mail,
          .service__auth-balance {
            max-width: 0;
            transition: 0.2s;
            height: 0;
          }
        }
      }
    }
    .aside__toggle {
      p {
        opacity: 0;
      }
    }
    .aside-brand {
      @media (min-width: 769px) {
        flex-direction: column;
        align-items: flex-start;

        .dropdown-toggle {
          width: 80px;
          margin-left: 0;
        }
        .aside-logo {
          margin-left: 25px;
          max-width: 35px;
        }
      }
    }
    .aside__menu {
      @media (min-width: 769px) {
        .aside__menu-item {
          padding-left: 10px;
          width: 48px;
          span {
            display: none;
          }
        }
        .aside__menu-children.active {
          .aside__menu-children-items {
            padding-left: 0px;
            padding-right: 0;
          }
        }
        .aside__menu-children.active {
          background: inherit;
          .aside__menu-item.active {
            background: #9678fb;
          }
        }
      }
    }
  }
}
