.footer {
  background: #f4f6fa;
  position: fixed;
  width: 100%;
  bottom: 0;
  .footer__content {
    display: flex;
    padding: 13px 0px 14px 0px;
    justify-content: space-between;
    align-items: center;

    a {
      font-size: 12px;
      font-weight: 500;
      line-height: 14.63px;
      letter-spacing: -0.5px;
      color: #051229;
      text-decoration: none;
    }
    .copyright {
      font-size: 12px;
      font-weight: 500;
      line-height: 14.63px;
      letter-spacing: -0.5px;
      text-align: left;
      color: #5d6981;
    }
  }
  @media (max-width: 991px) {
    position: static;
  }
  @media (max-width: 768px) {
    .footer__content {
      flex-direction: column;
      row-gap: 15px;
    }
  }
}

