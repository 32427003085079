.auth__form {
  // .tab-pane.fade:not(.show) {
  //     // display: none;
  //     position: absolute;
  // }
  // .tab-pane.fade {
  //     position: relative;
  // // }
  // overflow: hidden;
  .card-body {
    // max-height: 380px;
    overflow: hidden;
  }

  .tab-pane.fade:not(.show) {
    // transition: 0s !important;
    display: none;
  }

  .d-flex.justify-content-end {
    .btn:last-child {
      margin-right: 0 !important;
    }
  }

  .input-group {
    .btn:focus,
    .btn:hover {
      background: none !important;
    }
  }
}

.icon-eye {
  position: relative;
  cursor: pointer;
  padding-right: 5px;
  img {
    width: 20px;
  }

  &.active:before {
    content: "";
    width: 100%;
    height: 1.5px;
    background: #8161eb;
    position: absolute;
    transform: rotate(45deg);
    left: -2px;
    top: 50%;
    z-index: 3;
    width: 25px;
  }
}

.auth {
  @media (max-width: 1400px) {
    .card {
      width: 75% !important;
    }
  }
  @media (max-width: 991px) {
    .card {
      margin-left: -15px !important;
      margin-right: -15px !important;
      padding: 15px !important;
      width: calc(100% + 30px) !important;

      .card-body {
        padding: 0px !important;
      }

      .justify-content-end {
        column-gap: 15px;
      }
    }
  }
  @media (max-width: 768px) {
    li.nav-item {
      width: 50%;
      text-align: center;
    }
  }
}

.dark {
  .auth {
    background: #202020 !important;

    .container {
      background: transparent !important;
    }
  }

  .card {
    background: #303c54 !important;
  }

  .page h3, .auth h3 {
    color: #fff;
  }

  .nav-tabs .nav-link {
    color: #fff;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    background: #8c589e;
  }

  .password-info .password__item {
    color: #fff;
  }
}

.hidden {
  display: none;
}

.nav-item {
  cursor: pointer;
}