.balance {
  margin-top: 10px;
}
.grey-block {
  background: #e7ebf2;
  padding: 15px 15px;
  border-radius: 10px;
  height: 100%;
  min-height: calc(100vh - 235px);
  max-height: calc(100vh - 235px);
  @media (max-width: 991px) {
    min-height: unset;
    max-height: unset;
  }
}
.light-grey-block {
  background-color: #f4f6fa;
  border-radius: 10px;
  padding: 15px;
  height: 100%;
  min-height: calc(100vh - 235px);
  max-height: calc(100vh - 235px);
  overflow-y: auto;

  @media (max-width: 991px) {
    min-height: unset;
    max-height: unset;
    margin-left: -20px;
    margin-right: -20px;
  }
  .grey-block {
    max-height: unset;
    min-height: unset;
  }
  &::-webkit-scrollbar {
    width: 2px; /* ширина scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: #f4f6fa; /* цвет дорожки */
  }
  &::-webkit-scrollbar-thumb {
    background-color: #8161eb; /* цвет плашки */
    border-radius: 20px; /* закругления плашки */
    border: 3px solid #8161eb; /* padding вокруг плашки */
  }
}
.balance-wrapper {
  display: grid;
  grid-template-columns: 360px 1fr;
  column-gap: 15px;
  row-gap: 15px;
  grid-template-areas: "pay table";
  @media (max-width: 1430px) {
    grid-template-columns: 1fr;
    row-gap: 15px;
    grid-template-areas: 'pay' 'table';
  }
  &_crypto {
    grid-template-areas: "pay wallet" "table table";
    grid-template-rows: auto 1fr;
    @media (max-width: 1400px) {
      grid-template-areas: "pay" "wallet" "table";
      grid-template-rows: unset;
    }
  }
  .pay-block {
    grid-area: pay;
    height: 100%;
  }
  .pay-table {
    grid-area: table;
  }
  .balance-crypto {
    grid-area: wallet;
  }
}
body,
.page,
.panel__content {
  overflow-y: hidden;
}
.panel__inner {
  //   overflow-y: auto;
}

.pay-block {
  row-gap: 12px;
  display: flex;
  flex-direction: column;
  h6 {
    display: flex;
    align-items: center;
    column-gap: 6px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #051229;
    margin-bottom: 8px;
  }
  .form-control {
    background: #f4f6fa;
    padding: 10px 14px 11px 14px;
    border: none;
    border-radius: 10px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .input-group-text {
    background: #f4f6fa;
    border: none;
    border-radius: 10px;
  }
  .btn-group {
    width: 100%;
  }
  .primary {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: var(--Number2);
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 16px;

    &[disabled] {
      opacity: 0.7;
    }
  }
  .btn.btn-white.dropdown-toggle {
    background: #f4f6fa;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #051229;
    border-radius: 10px;

    &[aria-expanded="true"] {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
  .d-flex.align-items-center {
    width: 100%;
  }
  .com {
    display: inline-block;
    margin-left: 30px;
    font-family: Manrope;
    font-size: 12px;
    font-weight: 600;
    line-height: 16.39px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #fff;
    background-color: #fa3333;
    padding: 3px 9px 4px 11px;
    border-radius: 25px;
    margin-right: 10px;
  }
  .crypto-dropdown-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .dropdown-menu {
    background: #f4f6fa;
    padding: 0;
    width: 100%;
    border-radius: 10px;
    border: none;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;

    li {
      background: #f4f6fa;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.07px;
      letter-spacing: -0.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #051229;

      cursor: pointer;

      .crypto-dropdown-block {
        padding: 10px 14px;
        width: 100%;
        .com {
          margin-right: 20px;
        }
      }
      &:hover {
        background: #e7ebf2;
      }
    }
  }
  .crypto-menu {
    max-height: 300px;
    overflow-y: auto;
  }
  .dropdown-toggle {
    .crypto-item {
      padding: 0;
    }
  }
  .crypto-item {
    padding: 14px 21px;
    .crypto-item__name {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.07px;
      letter-spacing: -0.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      // margin-bottom: 5px;
      margin-bottom: 2.5px;
      margin-top: 2.5px;
    }

    .crypto-item__minmax {
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.63px;
      letter-spacing: -0.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #051229;
    }
  }
}

.mobile-list__line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile-list__item {
  padding: 20px 15px;
  background: #f4f6fa;
  border-radius: 10px;
  color: #051229;

  .mobile-list__value {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: right;
    text-underline-position: from-font;
  }
  .mobile-list__key {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .mobile-list__header {
    border-bottom: 1px dashed #5d6981;
    padding-bottom: 20px;
    & + .mobile-list__body {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  .mobile-list__footer {
    padding-top: 20px;
    border-top: 1px dashed #5d6981;
  }
  .mobile-list__body {
    // padding-top: 20px;
    // padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}
.mobile-list {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  width: 100%;
  row-gap: 15px;
}

.balance-crypto {
  display: grid;
  position: relative;
  grid-template-rows: auto 1fr auto;
  row-gap: 5px;
  grid-template-columns: auto;
  align-items: flex-start;
  h6 {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.94px;
    letter-spacing: -0.5px;
    color: #051229;
  }
  .crypto-item__minmax {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.5px;
  }
  .primary {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    padding: 10px 15px;
    align-self: flex-start;
  }
  .custom-alert {
    margin-top: 10px;
  }
  .balance-wallet__qr {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    grid-area: qr;
  }
  .balance-wallet-info {
    display: grid;
    margin-top: 20px;
    column-gap: 23px;
    grid-template-areas: "qr info-block";
    grid-template-columns: 216px 1fr;

    @media (max-width: 550px) {
      grid-template-areas: "qr" "info-block";
      grid-template-columns: 1fr;
    }
  }
  .balance-wallet-info__block {
    grid-area: info-block;
    padding-top: 11px;
  }
  .form-control {
    background: #f4f6fa;
    padding: 7px 5px 7px 15px;
    border: none;
    border-radius: 10px;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 14px;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    color: #051229;
  }
  .input-group-text {
    background: #f4f6fa;
    border: none;
    border-radius: 10px;
    padding: 4px 5px;
  }
  .copy-icon {
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #8161eb;
    border: none;
  }
  label {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    color: #051229;
    margin-bottom: 8px;
  }
  .balance-wallet__input {
    margin-top: 15px;
    max-width: 480px;
  }
}

.custom-alert {
  display: grid;
  grid-template-columns: 25px 1fr;
  grid-template-areas: "alert-icon alert-title" "alert-icon alert-text";
  row-gap: 6px;
  column-gap: 18px;
  align-items: center;
  padding: 10px 20px 12px 20px;
  border-radius: 10px;
  .alert-title {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.5px;
    letter-spacing: -0.5px;
    grid-area: alert-title;
  }
  .alert-text {
    grid-area: alert-text;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 14px;
    line-height: 17.07px;
    letter-spacing: -0.5px;
  }
  img {
    grid-area: alert-icon;
  }
  &_error {
    background: #fff4f7;
    .alert-title {
      color: #230404;
    }
    .alert-text {
      color: #3d0b0b;
    }
  }
  &_warning {
    background: #fff8ef;
    .alert-title {
      color: #2c1905;
    }
    .alert-text {
      color: #3d250b;
    }
  }
}
