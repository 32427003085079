button.primary,
a.primary {
  background: #8161eb;
  border-radius: 10px;
  border: none;
  box-shadow: none;
  outline: none;
  color: #fff;

  transition: 0.3s;

  @media (min-width: 768px) {
    &:hover {
      background: #a58afd !important;
    }
  }
}

button.error,
a.error {
  background: #fa3333;
  border-radius: 10px;
  border: none;
  box-shadow: none;
  outline: none;
  color: #fff;
  transition: 0.3s;

  &:hover {
    background: #ff7878 !important;
  }
}
