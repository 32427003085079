.app.dark {
    .accordion-header {
        .accordion-button {
            color: #fff !important;
            &:before {
                border-color: #fff;
            }
            &:not(.collapsed) {
                box-shadow: inset 0 -1px 0 #fff;
            }
        }
    }
}