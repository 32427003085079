body .react-confirm-alert-overlay {
  background: rgba(#000015, 0.7);
}

body .react-confirm-alert-body {
  width: 500px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 15px;
  }
}

body .react-confirm-alert-button-group {
  display: flex;
  justify-content: flex-end;
  button {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
  }
  button:first-child {
    color: #fff;
    background: $primary;
  }
  button:last-child {
    color: #fff;
    background: #9da5b1;
  }
}
.react-confirm-alert-body > h1 {
  font-size: 24px;
}
