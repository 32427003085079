.header {
  position: sticky;
  top: 0;
  background: #f4f6fa;
  z-index: 9;
  border-bottom: none;
  padding: 14px 0 11px 0px;
  .header__theme {
    display: flex;
    button {
      background: none;
      padding: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      img {
        filter: brightness(0);
      }
      &.active {
        img {
          filter: brightness(100%);
        }
      }
    }
    // FCAC19
    .header__separator {
      width: 1px;
      background: #000;
      height: 25px;
      margin-top: 7px;
    }
  }
  .dropdown-toggle {
    width: 90px;
    margin-left: 60px;
    border-radius: 10px;
    border: none;
    background: #e7ebf2;
    color: #051229;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    letter-spacing: -0.5px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding-right: 10px;
    height: 30px;
    &:focus,
    &.active {
      outline: none;
      box-shadow: none !important;
      background: #e7ebf2 !important;
    }
    @media (max-width: 1200px) {
      margin-left: 10px;
    }
    &:after {
      display: none;
    }
    .flag {
      border-radius: 50%;
      margin-right: 10px;
      transform: scale(0.6);
      margin-left: -8px;
      margin-right: 7px;
      margin-top: -4px;
      & + span {
        margin-right: 10px;
      }
    }
    &:hover {
      color: #051229;
    }
  }
  .reset-btn {
    margin-left: 50px;
    transform: scaleX(-100%);
  }
}

.header__content {
  display: flex;
  align-items: center;
  img {
    width: 159px;
    height: 31px;
    margin-right: 30px;
  }
  a,
  span {
    cursor: pointer;
    margin-right: 40px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    color: #051229;
    text-decoration: none;
    position: relative;
    &:hover {
      opacity: 0.8;
    }

    @media (max-width: 1200px) {
      margin-right: 15px;
    }
  }
}
.mobile-page-header {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  background-color: #f4f6fa;
  left: 0;
  width: 100%;
  z-index: 5;
  @media (min-width: 991px) {
    display: none;
  }
  .hamburger-lines {
    display: block;
    position: static;
    // top: 17px;
    // left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 3px;
    background: #8161eb;
    border-radius: 5px;
    height: 35px;
    width: 35px;
    cursor: pointer;
  }

  .hamburger-lines .line {
    display: block;
    height: 2px;
    width: 16px;
    border-radius: 10px;
    background: #fff;
    transition: 0.3s;
  }

  .nav-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }

  .nav-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .nav-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }

  .navbar .menu-items li {
    margin-bottom: 1.2rem;
    font-size: 1.5rem;
    font-weight: 500;
  }

  .active.hamburger-lines .line1 {
    transform: rotate(45deg) translate(3px, 3px);
  }

  .active.hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .active.hamburger-lines .line3 {
    transform: rotate(-45deg) translate(4px, -4px);
  }

  .mobile-page-header__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 20px 0px;
  }
}

.header__language {
  >span {
    display: none;
  }
}
.header {
  .container {
    height: 100%;
  }
  .header__language {
    &>span {
      display: none;
    }
  }
  @media (max-width: 991px) {
    .nonAuthorized {
      padding-top: 47px;
      position: relative;
      .auth-button {
        position: absolute;
        top: -10px;
        width: calc(100% - 40px);
      }
    }
    background: #f4f6fa;

    position: fixed;
    left: 0;
    transition: 0.2s;
    transform: translateX(-100%);
    width: 100%;
    // max-width: 450px;
    height: calc(100% - 67px);
    // box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    top: 67px;
    align-items: flex-start;
    > .conftainer {
      height: 100%;
    }
    .header__language {
      display: grid;
      width: 100%;
      align-items: center;
      grid-template-columns: 1fr auto;
      padding-bottom: 10px;
      .dropdown-menu {
        margin-bottom: 10px;
      }
      span {
        display: inline-block;
        font-family: Montserrat;
        font-weight: 400;
        font-size: 14px;
        line-height: 17.07px;
        letter-spacing: Number 2;
        color: #051229;
      }
    }
    .header__end {
      align-items: flex-end;
    }
    .d-flex.justify-content-between.w-100 {
      display: grid !important;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr;
      width: 100%;
      // align-items: flex-enda;
      height: 100%;
      .d-flex.header__end {
        justify-content: center;
      }
      .header__logo {
        display: none;
      }
      .dropdown-toggle {
        width: 100%;
        margin-left: 0;
      }
      .btn-group {
        width: 100%;
      }
    }
    .header__content {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 10px;
      padding-bottom: 10px;
      width: 100%;

      nav {
        width: 100%;
        flex-direction: column;
        a,
        span {
          padding: 19px 15px;
          background: #e7ebf2;
          width: 100%;
          font-size: 14px !important;
          border-radius: 10px;
          margin-bottom: 5px;
          font-family: Montserrat;
          font-weight: 500;
          font-size: 14px;
          line-height: 17.07px;
          color: #051229;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    &.active {
      transform: translateX(0);
    }
    .form-select {
      margin-left: 0;
      width: 100%;
    }
    .header__content img {
      margin-right: 0;
      // margin-left: -20px;
      width: auto;
    }
  }
}
.dark.admin .header select {
  background-color: #fff !important;
}

.header__end .primary {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  letter-spacing: var(--Number2);
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 16px 29px;
}
.header__end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 5px 5px 0px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 17.63px;
    height: 28px;
    border-radius: 10px;
    letter-spacing: var(--Number2);
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    cursor: pointer;
    .flag {
      border-radius: 50%;
      margin-right: 5px;
      transform: scale(0.6);
    }
  }
  .dropdown-menu {
    padding: 4px;
    border: none;
    background-color: #e7ebf2;
    margin-top: 7px;
    overflow: hidden;
    border-radius: 10px;
    width: 90px;
    max-width: 90px;
    min-width: 90px;
    z-index: 10;
    box-shadow: 0 0 1px rgb(0, 0, 0, 0.1);
  }
}

.modal-dialog {
  @media (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
  }
}

.mobile-page-haeder__logo {
  width: 159px;
  height: 31px;
}
