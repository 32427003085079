.Toastify__toast-container {
  .Toastify__toast {
    background: #F4F6FA !important;
    border-radius: 10px !important;
    padding: 12px 22px 15px 22px;
    //box-shadow: none;
    height: unset;
    max-height: unset;
    min-height: unset;


    span, .Toastify__toast-body {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 17.07px;
      letter-spacing: -0.5px;
      color: #051229;
    }

    &:before {
      content: "";
      position: absolute;
      left: 2px;
      top: 2px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    .Toastify__toast-icon {
      display: none;
    }

    .Toastify__close-button {
      align-self: center;
      opacity: 1;
    }

    .Toastify__toast-body {
      padding: 0;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }

    &--success {
      .Toastify__progress-bar {
        background: #50CB25;
      }

      &:before {
        background: #50CB25;
      }
    }

    &--error {
      .Toastify__progress-bar {
        background: #FA3333;
      }

      &:before {
        background: #FA3333;
      }
    }
    &--info {
      .Toastify__progress-bar {
        background: rgba(52,152,219);
      }

      &:before {
        background: rgba(52,152,219);
      }
    }
  }
}