.form-group {
    label {
        display: block;
        font-size: 18px;
        line-height: 130%;
        margin-bottom: 5px;
    }
    .input {
        background: transparent;
        font-size: 18px;
        line-height: 130%;
        padding: 6px;
        border-radius: 5px;
        border: 1px solid #9879a4;
        height: 35px;
        &:required {
            &+label {
                position: relative;
                &:after {
                    content: "*";
                }
            }
        }
    }
    .input-block {
        display: flex;
        flex-direction: column-reverse;
    }
}

.file-upload {
    input {
        display: none;
    }
    border:2px solid #9879a4;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 6px;
    background: #fff;
    color: #252523;
    display: flex !important;
    justify-content: center;
    align-items: center;
    span {
        text-align: center;
    }
}