@import "./components/fonts.scss";
@import "./components/var.scss";
@import "./components/button.scss";
@import "./components/badge.scss";
@import "./components/accordion.scss";
@import "./components/accordion.dark.scss";
@import "./components/form.scss";
@import "./components/form.dark.scss";
@import "./components/type.scss";
@import "./components/block.scss";
@import "./components/container.scss";
@import "./components/block.dark.scss";
@import "./components/icon.scss";
@import "./components/flags.scss";
@import "./components/services.scss";
@import "./components/modal.scss";
@import "./components/modal.dark.scss";
@import "./components/table.scss";
@import "./components/table.dark.scss";
@import "../../../node_modules/@coreui/coreui/scss/coreui.scss";
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.container {
  // @media (min-width: 992px) and (max-width: 1100px) {
  //     max-width: 768px;
  // }
  // @media (min-width: 1101px) and (max-width: 1399px) {
  //     max-width: 1100px;
  // }
  // @media (max-width: 1600px) and (min-width: 1400px) {
  //     max-width: 1100px;
  // }
  // @media (max-width: 992px) and (max-width: 1400px) {
  // }
  &_xl {
    max-width: 1500px;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
  }

  @media (max-width: 991px) {
    max-width: 100% !important;
  }
}

.shadow-block {
  padding: 24px;
  // border-radius: 8px;
  // background: #fff;
  // box-shadow: 0 0 4px rgb(10, 15, 0, 0.2);
  @media (max-width: 991px) {
    padding: 10px 0 10px 0px;
  }
}

.bottom-bar + * {
  margin-bottom: 74px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}
.main-rules + .not-found,
.footer + .not-found {
  display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.manrope {
  font-family: "Manrope", serif;
  font-optical-sizing: auto;

}