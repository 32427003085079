.services__header-wrapper {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: #f4f6fa;
}
.services-list.non-authorized {
  .service__star {
    display: none;
  }
  .service__info {
    grid-template-areas: "s-bg s-name" "s-bg s-count";
    grid-template-columns: 40px 1fr;
  }
}
.services__header-wrapper {
  padding-top: 15px;
}
.services-list-wrapper {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px; /* ширина scrollbar */
  }
  &::-webkit-scrollbar-track {
    background: #f4f6fa; /* цвет дорожки */
  }
  &::-webkit-scrollbar-thumb {
    background-color: #8161eb; /* цвет плашки */
    border-radius: 20px; /* закругления плашки */
    border: 3px solid #8161eb; /* padding вокруг плашки */
  }
  @media (max-width: 991px) {
    max-height: 50vh;
  }
}
.services__header {
  padding: 15px 95px 9px 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
  line-height: 14.63px;
  letter-spacing: -0.5px;
  color: #5d6981;
  font-weight: 400;
  overflow: hidden;
  z-index: 2;
  &_light {
    padding: 15px 75px 9px 65px;
  }
  @media (max-width: 768px) {
    padding: 15px 50px 9px 85px;
  }
  @media (max-width: 401px) {
    padding: 15px 45px 9px 85px;
  }
}
.services-list {
  border-radius: 10px;
  max-height: calc(100vh - 166px);
  padding: 0px 15px 15px 15px;
  overflow-y: hidden;
  background: #f4f6fa;
  display: grid;
  grid-template-rows: auto 1fr;
  &::-webkit-scrollbar {
    display: none;
  }
  position: relative;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  @media (max-width: 768px) {
    max-height: unset;
  }
  @media (max-width: 991px) {
    padding: 15px 20px;
    // width: calc(100% + 30px);
    margin-top: 75px;
  }
}
.dark {
  .services-list {
    &:after {
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.5) 50%
      );
    }
  }
}
.services__search input {
  border: none;
  padding: 10px 40px 10px 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #5d6981;
  border-radius: 10px;
  background: #e7ebf2;
  &:focus,
  &:active {
    outline: none;
  }
}

.dark {
  .services__search input {
    background: #303c54 !important;
  }
  .services__search svg {
    filter: invert(1);
  }
  .services__favorite {
    &_default {
      filter: invert(1) opacity(1);
    }
  }
}
.services__search {
  margin-bottom: 8px;
  position: sticky;
  top: 0;
  svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    font-size: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5);
  }
}
.services__search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 17px;

  width: 12.75px;

  height: 12.75px;
}
.services-list__header {
  // position: sticky;
  // top: 0;
  // background:#f4f6fa;
  // z-index: 3;
}
.services__item {
  background: #e7ebf2;

  border-radius: 10px;
  margin-bottom: 8px;
  &.light {
    .services__item-header {
      grid-template-columns: 1fr 90px;
      .service__price {
      }

      @media (max-width: 401px) {
        grid-template-columns: 1fr 90px !important;
      }
    }
    .services__header {
      padding: 15px 65px 9px 65px;
    }
  }
  .services__item-header {
    position: relative;
    display: grid;
    padding: 12px 26px 12px 15px;
    cursor: pointer;
    grid-template-columns: 1fr 90px 8px;
    align-items: center;
    column-gap: 25px;
    .with-preloader {
      padding-top: 0;
      align-items: center;

      .preloader-block {
        align-self: center;
      }
    }

    @media (max-width: 401px) {
      padding: 8px 12px;
      // grid-template-columns: 1fr 70px;
      grid-template-columns: 1fr 90px 16px !important;
    }
    @media (max-width: 550px) {
      column-gap: 15px;
      grid-template-columns: 1fr 90px 8px;
      overflow-x: hidden;
    }
  }
  .service__spinner {
    position: absolute;
    left: 50%;
    top: 16px;
  }
  &.show {
    .service__toggle {
      transform: rotate(-180deg);
    }
  }
}
.service__toggle {
  transition: 0.2s;
}
.dark {
  .services__item {
    &.show {
      background: rgba(0, 0, 0, 0.2);
    }
  }
}
.services__favorite {
  width: 16px;
  cursor: pointer;
}
.service__country-header {
  .service__price {
    display: flex;
    align-items: center;
    .primary.buy {
      column-gap: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 44px;
    }
    .service__star {
      margin-left: 9px;
    }
  }
}
.service__price {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  letter-spacing: -0.5px;
  color: #051229;
  text-align: right;

  .buy {
    margin-left: 10px;
  }
  @media (max-width: 400px) {
    font-size: 12px;
  }
}
.services__item-header {
  .service__info {
    grid-template-areas: "s-bg s-name" "s-bg s-count";
    grid-template-columns: 40px 1fr;
  }
}
.service__info {
  display: grid;
  grid-template-areas: "s-star s-bg s-name" "s-star s-bg s-count";
  grid-template-columns: 30px 40px 1fr;
  row-gap: 3px;
  align-items: center;

  .service__star {
    grid-area: s-star;
  }
  .bg {
    border-radius: 6px;
    grid-area: s-bg;
  }
  .service__name {
    grid-area: s-name;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.5px;
    line-height: 19.5px;
    text-align: left;
    color: #051229;
    text-transform: capitalize;
    @media (max-width: 400px) {
      font-size: 14px;
    }
  }
  .service__count {
    grid-area: s-count;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    text-align: left;
    letter-spacing: -0.5px;
    color: #5d6981;

    @media (max-width: 400px) {
      font-size: 12px;
    }
  }
}

.services__not-found {
  text-align: center;
}

.service__product {
  display: grid;
  padding: 8px 24px 8px 12px;
  position: relative;
  grid-template-columns: 14px 1fr 50px 32px;
  align-items: center;
  column-gap: 16px;
  .flag {
    transform: scale(2.2);
    margin-bottom: -12px;
    margin-left: -1px;
    border-radius: 2px;
  }

  &.disabled {
    &:after {
      content: "";
      width: 100%;
      position: absolute;
      height: 100%;
      background: rgba(255, 255, 255, 0.1);
    }
  }
}

.service__products-search {
  width: calc(100% - 33px);
  margin: 0 auto;
  position: relative;
  input {
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    text-align: left;
    color: #5d6981;
    border-radius: 10px;
    padding: 10px 43px 10px 43px;
    border: none;
    box-shadow: none;
    margin-bottom: 15px;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    color: rgba(0, 0, 0, 0.5);
  }
}
.dark {
  .service__products-search svg {
    filter: invert(1);
  }
}
.service__cart {
  width: 32px;
  height: 24px;
  border-radius: 4.24px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}

.service__filters {
  padding-top: 15px;
  width: calc(100% - 30px);
  margin: 0 auto;
  margin-bottom: 14px;
  .service__filters-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .service__filters-sort-by {
      font-size: 12px;
      line-height: 14.63px;
      letter-spacing: -0.5px;
      font-weight: 500;
      color: #5d6981;
      & + span {
        color: #5d6981;
        line-height: 14.63px;
        font-size: 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        cursor: pointer;
        .service__filters-sort-icon {
          width: 12px;
          height: 12px;
          border-radius: 2px;
          margin-right: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;
          position: relative;
          &:after {
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #8161eb;
            opacity: 0;
            transition: 0.2s;
          }
        }
        &:hover {
          .service__filters-sort-icon {
            &:after {
              @media (min-width: 769px) {
                opacity: 0.2;
              }
            }
          }
        }
        &.active,
        &.active:hover {
          .service__filters-sort-icon {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.service__filters span.disabled {
  pointer-events: none;
}

.service__data {
  // background: linear-gradient(180deg, #f4f6fa 0%, #e7ebf2 19.32%);
  border-radius: 10px;
  margin-left: 15px;
  width: calc(100% - 30px);
  padding: 13px 15px;
}
.service__product-country {
  background: #f4f6fa;
  border-radius: 10px;
  padding: 15px 13px;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 14px;
  .service__country-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .service__info {
    display: grid;

    .flag {
      grid-area: s-bg;
      // transform: scale(1.5) scale(1.7);
      border-radius: 2px;
    }
  }
  .service__product-subheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;

    .service__product-subheader-count {
      padding: 10px 15px 11px 14px;
      color: #051229;
      border-radius: 10px;
      background: #e7ebf2;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 17.07px;
      letter-spacing: -0.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
  .service__product-prices {
    padding: 10px 15px;
    // background: #e7ebf2;
    background: linear-gradient(180deg, #f4f6fa 0%, #e7ebf2 19.32%);
    border-radius: 10px;
    margin-top: 24px;
  }
}
.service__product-prices-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.service__product-prices-items {
  height: 100%;
  overflow-y: auto;
  // transition: 0.2s max-height;
  display: none;
  &.active {
    display: block;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  // /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.services__buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "link1 link2";
  align-items: center;
  column-gap: 12px;
  margin-top: 15px;
  @media (max-width: 991px) {
    display: grid;
    grid-template-areas: "link1 link2" "show show";
    row-gap: 12px;
  }

  a.primary {
    background: #8161eb;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    border-radius: 10px;
    padding: 15px 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: var(--Number2);
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    &:first-child {
      grid-area: link1;
    }
    &:nth-child(2) {
      grid-area: link2;
    }

    // column-gap: 37px;
    img {
      margin-right: 15px;
    }

    @media (max-width: 991px) {
      font-size: 13px;
      line-height: 16px;
      padding: 15px 10px;
    }
  }
  .show-list {
    display: none;

    @media (max-width: 991px) {
      display: flex;
      font-size: 13px;
      line-height: 16px;
      padding: 15px 17px;
      grid-area: show;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.service__prices-item-left {
  display: flex;
  align-items: center;
  .manrope {
    margin-left: -3px;
    margin-right: -3px;
  }
  .flag {
    transform: scale(0.9);
    margin-right: 13px;
    margin-left: 5px;

    & + div {
      .service__prices-item-amount {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 17.07px;
        letter-spacing: -0.5px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #051229;
      }
      .service__prices-item-count {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.63px;
        letter-spacing: -0.5px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #5d6981;
      }
    }
  }
  .bg {
    transform: scale(0.6);
  }
}
.buy {
  padding: 8px 11px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  height: 38px;
  display: grid;
  grid-template-columns: 22px auto;
  column-gap: 10px;
  align-items: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  letter-spacing: -0.5px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  // min-width: 113px;
  img,
  .spinner-border {
    // margin-right: 10px;
    //
  }
}
.service__product-show {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.63px;
  letter-spacing: -0.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #051229;
  column-gap: 7px;
  margin-bottom: 15px;
  cursor: pointer;
}

.services__auth {
  padding: 17px;
  width: 100%;

  border: none;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  letter-spacing: -0.5px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #8161eb;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e6e4f8;
  border-radius: 10px;
  margin-top: 15px;

  img {
    margin-right: 14px;
  }
}

.no-data {
  width: 100%;
  color: #5d6981;
  background: #e7ebf2;
  font-family: Montserrat;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.07px;
  letter-spacing: -0.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.rdt_Table {
  .no-data {
    border-radius: 0;
  }
}

.service__auth-balance {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.29px;
  letter-spacing: -0.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  grid-area: a-bal;
  @media (max-width: 991px) {
    font-size: 13px;
    line-height: 16px;
  }
}
.service__auth-mail {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.07px;
  letter-spacing: -0.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 9px;
  grid-area: a-mail;
  max-width: 220px;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-bottom: 0;

  .service__auth-mail-name {
    display: inline-block;
    max-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.services__auth-block {
  display: grid;
  grid-template-areas: "a-ava a-mail a-buttons" "a-ava a-bal a-buttons";
  grid-template-columns: auto 1fr auto;
  column-gap: 18px;
  align-items: center;
  margin-top: 15px;

  img {
    grid-area: a-ava;
  }

  .service__auth-buttons {
    grid-area: a-buttons;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 7px;

    a,
    button {
      border: none;
      border-radius: 10px;
      width: 38px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .service__auth-button {
      &_order {
        background-color: #dce6f4;
      }
      &_balance {
        background-color: #dcf4dd;
      }
      &_logout {
        background-color: #fbe0e5;
      }
    }
  }
}
.panel__content {
  max-height: calc(100vh - 110px);
  overflow-y: auto;

  @media (max-width: 991px) {
    min-height: unset;
    max-height: unset;
  }
}

.page,
body,
.panel__content {
  @media (max-width: 991px) {
    overflow-y: auto !important;
  }
}

.hiddenServices {
  @media (max-width: 991px) {
    display: none !important;
  }
}

.panel__content {
  overflow-x: hidden !important;
}
.with-preloader {
  display: flex;
  justify-content: center;
  // align-items: center;
  padding-top: 100px;
  background: #e7ebf2d1;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  z-index: 5;
}

.panel__inner {
  position: relative;
}
.cookie {
  position: fixed;
  bottom: 28px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 65.7px 0px #b7bfc9;
  background: #f4f6fa;
  border-radius: 10px;
  padding: 15px 18px 10px 22px;
  max-width: 492px;
  color: #051229;
  &:before {
    content: "";
    width: 10px;
    height: 10px;

    background: #8161eb;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
  }
  .cookie__footer {
    display: flex;
    justify-content: flex-end;
  }
  .cookie-close {
    position: absolute;
    right: 11px;
    top: 10px;
    cursor: pointer;
  }

  h6 {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 14px;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 10px;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.07px;
    letter-spacing: -0.5px;
  }
  .primary {
    padding: 10px 16px;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.07px;
    letter-spacing: -0.5px;
  }
}

.order-flag-service {
  height: 22px;
  .bg {
    transform: scale(0.6);
  }
  .flag {
    transform: scale(0.65) scale(1.06);
  }
  span.manrope {
    margin-left: -5px;
    margin-right: -4px;
  }
}

.filters-wrapper__items {
  width: 100%;
}
.csv {
  // align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.07px;
  letter-spacing: -0.5px;
  padding: 10px 15px;
  min-width: 135px;
  white-space: nowrap;
}
.csv-wrapper {
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-end !important;
    // justify-content: flex-end;
  }
}
.form-control {
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.panel__inner {
  .csv {
    align-self: flex-end;
    margin-bottom: 40px;
  }
}

.services__tabs {
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  padding: 5px;
  background: #e7ebf2;
  border-radius: 10px;
  margin-top: 15px;
  position: relative;
  &:before {
    content: "";
    width: calc(50% - 7.5px);
    position: absolute;
    background: #f4f6fa;
    z-index: 1;
    height: 33px;
    border-radius: 10px;
    left: 5px;
    transition: 0.2s;
  }
  &_favorites {
    &:before {
      left: calc(50% + 3.75px);
    }
  }
  .services__tabs-badge {
    padding: 0 5px;
    height: 16px;
    border-radius: 8px;
    background: #dce6f4;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4869ed;
  }
  .services__tabs-item {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 7px;
    width: 100%;
    border-radius: 10px;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 14px;
    line-height: 17.07px;
    height: 33px;
    letter-spacing: -0.5px;
    color: #5d6981;
    cursor: pointer;
  }
}
