//.settings {
//  label {
//    display: flex;
//    align-items: center;
//    column-gap: 5px;
//  }
//
//  .d-flex.align-items-center {
//    flex-wrap: wrap;
//    row-gap: 10px;
//  }
//}
//
//.settings__stores {
//  div {
//    display: flex;
//    flex-direction: column;
//    row-gap: 10px;
//  }
//}
//
//.token {
//  margin-top: 15px;
//  button {
//    display: flex;
//    align-items: center;
//
//    .spinner-border {
//      margin-right: 10px;
//    }
//  }
//  @media (max-width: 991px) {
//    .alert.alert-primary.show {
//      display: flex !important;
//    }
//  }
//}
//.user-form {
//  display: flex;
//  flex-wrap: wrap;
//  justify-content: flex-start;
//  column-gap: 20px;
//  row-gap: 20px;
//
//  & > div,
//  & > label {
//    min-width: 300px;
//    margin: unset;
//
//    @media (max-width: 991px) {
//      min-width: calc(33% - 15px);
//      label.form-label {
//        margin-bottom: 0;
//      }
//    }
//    @media (max-width: 768px) {
//      min-width: calc(50% - 10px);
//    }
//    @media (max-width: 450px) {
//      min-width: 100%;
//    }
//  }
//  & > label {
//    margin-bottom: 8px;
//    align-self: flex-end;
//    display: flex;
//    align-items: center;
//    column-gap: 10px;
//    font-size: 14px;
//  }
//  @media (max-width: 768px) {
//    row-gap: 10px;
//  }
//}
//.user-form__wrapper {
//  display: flex;
//  justify-content: space-between;
//}
//
//.settings {
//  .user-form {
//    & > div,
//    & > label {
//      min-width: 100%;
//    }
//  }
//}
//.prefix-modal {
//  .input-group-text {
//    padding: 0;
//    // border: none;
//    border-radius: 0.25rem !important;
//    margin-right: 5px;
//    position: relative;
//    > .flag {
//      margin-left: 10px;
//      position: absolute;
//      z-index: 2;
//    }
//  }
//  .form-control {
//    border-top-left-radius: 0.25rem !important;
//    border-bottom-left-radius: 0.25rem !important;
//  }
//  .basic-single {
//    div[class*="-control"] {
//      border-color: transparent;
//    }
//    .select__value-container {
//      width: 90px;
//      text-align: left;
//      padding-left: 10px;
//    }
//  }
//  .select__option {
//    > div {
//      text-align: left;
//    }
//  }
//  .select__indicator-separator {
//    display: none;
//  }
//}
//
//.prefix {
//  box-shadow: 0 0 3px rgba(0,0,0,0.1);
//  padding: 15px;
//  margin-left: 3px;
//  border-radius: 6px;
//
//  @media (max-width:768px) {
//    h6 {
//      font-size: 18px;
//      line-height: 24px;
//    }
//    p {
//      font-size: 14px;
//      line-height: 18px;
//    }
//  }
//  .accordion {
//    z-index: 0;
//  }
//  .accordion-button {
//    padding-left: 0;
//    padding-right: 0;
//
//    .flag {
//      margin-right: 10px
//    }
//  }
//  .accordion-item {
//    border-bottom: 1px solid #ccc;
//    border-radius: 0;
//
//    &:last-child {
//      border-bottom: 0;
//    }
//
//  }
//  .prefix-list {
//    margin-bottom: 15px;
//    padding-bottom: 10px;
//  }
//  .prefix-header {
//    display: flex;
//    align-items: center;
//    text-transform: capitalize;
//  }
//  .prefix-item {
//    display: flex;
//    justify-content: space-between;
//    align-items: center;
//    margin-bottom: 5px;
//  }
//  .prefix-item__end {
//    column-gap: 5px;
//    display: flex;
//  }
//
//  @media (max-width: 1400px) {
//    margin-bottom: 50px;
//  }
//}
.borderline {
  text-decoration: line-through;
}

.settings {
  background: #e7ebf2;
  padding: 18px 15px;
  border-radius: 10px;
  column-gap: 15px;
  display: grid;
  grid-template-columns: 1fr 430px 1fr;
  grid-template-areas: "userdata twofa token";
  position: relative;
  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "userdata token" "twofa twofa";
  }
  .userdata {
    grid-area: userdata;
  }
  .twofa {
    grid-area: twofa;
  }
  .token {
    grid-area: token;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 30px;
    grid-template-areas: "userdata" "twofa" "token";
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  h6 {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 8px;
  }

  input.form-control {
    background: #f4f6fa;
    border: none;
    box-shadow: none;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10.5px 25px 10.5px 15px;
    color: #5d6981;

    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  input:-internal-autofill-selected {
    background-color: #f4f6fa !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }

  .input-group-text {
    border: none;
    box-shadow: none;
    background: #f4f6fa !important;
    padding-right: 10px;
    padding-left: 14px;
    border-radius: 10px;
  }

  input:auto-fill {
  }

  input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: -internal-light-dark(
      rgb(232, 240, 254),
      rgba(70, 90, 126, 0.4)
    ) !important;
    color: fieldtext !important;
  }

  h5 {
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #051229;
    margin-bottom: 8px;
  }

  .primary {
    padding: 10px 16px 11px 16px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .twofa {
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #051229;
    margin-bottom: 18px;
  }

  .qr-code {
    position: relative;
    max-width: 165px;

    .qr-code__block {
      position: absolute;
      background: rgba(255, 255, 255, 0.8);
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & + div {
      width: 100%;
    }
  }

  .qr-wrapper {
    display: flex;
    column-gap: 18px;
    width: 100%;
    align-items: flex-end;
    h4 {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 17.07px;
      letter-spacing: -0.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #051229;
    }

    .settings__stores {
      display: flex;
      margin-top: 19px;
      column-gap: 7px;
      justify-content: space-between;
      img {
        //width: 115px;
      }

      @media (max-width: 500px) {
        flex-direction: column;
        row-gap: 7px;
      }
    }
  }
  h3 {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #051229;
    margin-bottom: 18px;
  }
}

.prefix {
  padding: 23px;
  .prefix-header {
    column-gap: 37px;
    @media (max-width: 550px) {
      flex-direction: column;
      row-gap: 16px;
      text-align: center;
      h5,
      p {
        text-align: center;
      }
    }
  }
  h5 {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #051229;
    margin-bottom: 13px;
  }
  p {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.85px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #051229;
    margin-bottom: 16px;
  }
  .primary {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.07px;
    letter-spacing: -0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 10px 15px;
  }
  .prefix__body {
    margin-top: 45px;
    h6 {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 17.07px;
      letter-spacing: -0.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #051229;
    }
  }
  .prefix-list {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 11px;

    @media (max-width: 720px) {
      flex-wrap: nowrap;
      flex-direction: column;
      .btn-group,
      .btn.btn-white.dropdown-toggle {
        width: 100% !important;
      }
    }
    .btn-group {
      position: relative;
      .show {
        &:before {
          opacity: 1;
        }
      }
      // &:before {
      //   content: "";
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   width: 100%;
      //   height: 100%;
      //   box-shadow: 0px 4px 55.8px 0px #5f6e881a;
      //   opacity: 0;
      //   transition: 0.2s;
      // }
    }
    .prefix-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2px 15px;
    }
    .prefix-item__end {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background: none;
      }
    }
    .prefix-item__start {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .flag {
        transform: scale(0.65);
        border-radius: 60%;
      }

      b {
        display: inline-flex;
        align-items: center;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.07px;
        letter-spacing: -0.5px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #051229;
        span {
          color: #5d6981;
        }
      }
    }
    .btn.btn-white.dropdown-toggle {
      background: #f4f6fa;
      padding: 2px 15px;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 320px;

      &[aria-expanded="true"] {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
      & + .dropdown-menu {
        width: 100%;
        padding: 0;
        border-radius: 10px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border: none;
        background-color: #f4f6fa;
        &.show:after {
          content: "";
          width: 100%;
          height: calc(100% + 38px);
          position: absolute;
          left: 0;
          bottom: -38px;
          box-shadow: 0px 4px 55.8px 0px #5f6e881a;
          border-radius: 10px;
          pointer-events: none;
        }
      }
      b {
        display: inline-flex;
        align-items: center;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.07px;
        letter-spacing: -0.5px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #051229;
      }
      .flag {
        transform: scale(0.65);
        border-radius: 60%;
      }
      .prefix-country-name {
        display: inline-block;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 5px;
        text-transform: capitalize;
      }
    }
  }
}

.basic-single {
  width: 100%;

  div[class*="-control"] {
    border-radius: 10px !important;
    border: none;
    background-color: #f4f6fa;
  }
  span[class*="-indicatorSeparator"] {
    display: none !important;
  }
  svg[class*="-Svg"] {
    fill: #000000;
    color: #000000;
    stroke: #000000;
  }
  div[class*="-MenuList"] {
    background: #f4f6fa;
    padding: 8px;

    &::-webkit-scrollbar {
      width: 2px; /* ширина scrollbar */
    }
    &::-webkit-scrollbar-track {
      background: #f4f6fa; /* цвет дорожки */
    }
    &::-webkit-scrollbar-thumb {
      background-color: #8161eb; /* цвет плашки */
      border-radius: 20px; /* закругления плашки */
      border: 3px solid #8161eb; /* padding вокруг плашки */
    }
  }
  div[class*="-option"] {
    background: #f4f6fa;
    border-radius: 10px;
    padding: 0px 5px;
    cursor: pointer;

    &:hover {
      background: #ffffff;
    }
  }
}
.prefix-modal {
  .input-group-text {
    padding: 0;
    margin-right: 10px;
    background-color: transpaarent !important;
    border-radius: 10px;
  }
  .form-control {
    border-radius: 10px !important;
    padding: 10px 18px !important;
    height: 38px;
  }
}

.prefix-item-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  letter-spacing: -0.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #5d6981;
  column-gap: 10px;
  .flag {
    transform: scale(0.65);
    border-radius: 60%;
  }
}

.balance-block {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.07px;
  letter-spacing: -0.5px;
}

.primary.primary-copy {
  padding: 4px 16px !important;
  border-radius: 10px !important;
}
.token-modal {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  line-height: 17.07px;
  letter-spacing: -0.5px;
  color: #051229;
}

.toggler_disabled {
  cursor: progress;
  .react-toggle-thumb {
    opacity: 0.7;
    cursor: progress;
  }
  .react-toggle {
    cursor: progress;
  }
}
