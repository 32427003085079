.services {
    padding: 100px 0px;

    @media (max-width: 768px) {
        padding: 50px 0px;
    }
    h3 {
        text-align: center;
        @media (max-width: 768px) {
            font-size: 20px;
        }
    }
    p {
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        @media (max-width: 768px) {
            font-size: 16px;
        }
    }
    .services__content {
        display: grid;
        grid-template-columns: repeat(auto-fill, calc(25% - 25px));
        column-gap: 25px;
        row-gap: 25px;
        img {
            width: 100%;
            height: 150px;
            margin-bottom: 10px;
            object-fit: cover;
        }
        .services__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
                margin-bottom: 0;
                font-weight: 700;

                @media (max-width: 768px) {
                    font-size: 16px;
                    font-weight: 400;
                }
            }
            box-shadow: 0 0 6px rgba(0, 0, 0, .1);
            padding: 10px;
            border-radius: 6px;
        }

        @media (max-width: 768px) {
            grid-template-columns: repeat(auto-fill, calc(50% - 12.5px));
            justify-content: space-between;
        }
        @media (max-width: 500px) {
            grid-template-columns: 1fr;
        }
    }
}