.breadcrumbs {
  display: flex;
  align-items: center;
  margin-bottom: 20px;    overflow-x: auto;

  a {
    color: #252523;
    // text-decoration: none;
    position: relative;
    margin-right: 15px;
    white-space: nowrap;
    &:after {
      content: "/";
      position: absolute;
      right: -10px;
    }
    &[disabled] {
      text-decoration: none;
      pointer-events: none;  
    }

    &:last-child {
        margin-right: 0;
        &:after {
            display: none;
        }
    }
    @media (max-width: 768px) {
        font-size: 13px;
    }
  }
}

.dark {
    .breadcrumbs {
        a {
            color: #fff;
        }
    }
}