.about.light-grey-block {
  padding: 0;
  background-color: transparent;
  min-height: calc(100vh - 165px);
  max-height: calc(100vh - 165px);
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 991px) {
    min-height: unset;
    max-height: unset;
    margin-left: 0;
    margin-right: 0;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 36.57px;
    letter-spacing: -0.5px;
    margin-bottom: 24px;
    @media (max-width: 991px) {
      margin-bottom: 18px;
    }
  }
  h4 {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.5px;
    max-width:100%;
    margin-bottom: 56px;
    white-space: pre-wrap;
    @media (max-width: 1400px) {
      max-width: unset;
      margin-bottom: 40px;
    }
    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
  }
  .about__items {
    display: flex;
    justify-content: space-between;
    margin-bottom: 39px;
    column-gap: 30px;

    @media (max-width: 1600px) {
      column-gap: 15px;
    }
    @media (max-width: 1400px) {
      flex-direction: column;
      row-gap: 15px;
    }
  }
  .about__item {
    display: grid;
    grid-template-columns: 130px 1fr;
    column-gap: 30px;
    row-gap: 16px;
    align-items: center;
    @media (max-width: 1600px) {
      column-gap: 15px;
    }
    grid-template-areas: "about-image about-title" "about-image about-subtitle";
    @media (max-width: 991px) {
      grid-template-columns: 84px 1fr;
      row-gap: 10px;
    }
  }
  .about__item-image {
    grid-area: about-image;
    background: #f4f6fa;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 130px;
    height: 130px;
    @media (max-width: 991px) {
      width: 84px;
      height: 84px;

      img {
        width: 60px;
      }
    }
  }
  .about__item-title {
    grid-area: about-title;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    letter-spacing: -0.5px;
    text-align: left;
    color: #051229;
    align-self: flex-end;
    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 19px;
    }
  }
  .about__item-subtitle {
    align-self: flex-start;
    grid-area: about-subtitle;
    color: #051229;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.5px;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.about__info {
  background: url("../../assets/images/about-bg.png") no-repeat;
  background-size: cover;
  border-radius: 20px;
  padding: 30px 30px 38px 30px;
  margin-bottom: 39px;
  .about__logo {
    margin-bottom: 23px;
    @media (max-width: 991px) {
      margin-bottom: 16px;
    }

  }
  @media (max-width: 991px) {
    padding: 26px 15px;
    margin-bottom: 30px;
  }
  h5 {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.26px;
    letter-spacing: -0.5px;
    margin-bottom: 38px;
    @media (max-width: 991px) {
      margin-bottom: 16px;
    }
  }
  ul {
    list-style-type: none;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.85px;
    letter-spacing: -0.5px;
    color: #fff;
    padding-left: 0;
    li {
      margin-bottom: 16px;
      padding-left: 25px;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }

      img {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
.about__subtext {
  color: #051229;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.5px;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
}
