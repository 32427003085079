.accordion-header {
    .page__subtitle {
        margin-bottom: 0;
    }
    .accordion-button {
        background: transparent !important;
        color: #202020 !important;
        &:after {
            display: none;
        }
        &:before {
            width: 15px;
            content: "";
            position: absolute;
            right: 25px;
            height: 1em;
            border-right: 2px solid #202020;
            border-bottom: 2px solid #202020;
            transform: rotate(45deg);
            transition: 0.2s;
        }
        &:not(.collapsed) {
            &:before {
                transform: rotate(-135deg);
            }
            box-shadow: inset 0 -1px 0 #202020;
        }
        &:focus {
            border: none;
            outline: none;
            box-shadow: none;
        }
    }
}