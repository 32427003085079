.routing-tabs {
  padding: 17px 0px 22px 0px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;

  a {
    font-size: 24px;
    font-weight: 700;
    line-height: 29.26px;
    letter-spacing: -0.5px;
    color: #c3cbd9;
    text-decoration: none;
    margin-right: 30px;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    &.active,
    &:hover {
      color: #051229;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -4px;
      height: 1px;
      background-color: #8161eb;
      width: 80%;
      opacity: 0;
    }

    &.active:after {
      opacity: 1;
    }
  }

  @media (max-width: 1400px) {
    a {
      font-size: 22px;
      line-height: 26px;
    }
  }
  @media (max-width: 991px) {
    justify-content: center;
    column-gap: 20px;
    row-gap: 13px;

    a {
      margin-right: 0;
      font-size: 18px;
      line-height: 22px;
    }
  }
}
